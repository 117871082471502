/* Left Navbar */
.leftNavigation{
position: fixed;
left:0 ;
width: 289px;
background: #174f8f;
height: 100%;
transition: 1s;
overflow: hidden;
white-space: nowrap;
z-index: 9999;
animation: fadeInLeft 1s;
}
.headerLeft{
  background: #a37af4;
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 3fr 10fr 2fr;
}
.colm{
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  margin: 13px 0 0 6px;
}
.DashBoardIcon{
  font-size: 29px;
  color: #fff;
  margin: 0 18px 0 0;
  cursor: pointer;
}
.leftButton:hover{
  background: #2c66a9;
}
.leftButton{
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 2fr 10fr;
  cursor: pointer;
  border-bottom: 1px solid #4e94e5;
}
.iconLeft{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 2px;
  background: #042954;

}
.iconLeft img{
  max-width: 100%;
  background: #fff;
  border-radius: 50%;
}
.leftText{
  font-size: 17px;
  color: #fff;
  text-align: left;
  margin: 4px 0 0 15px;
}
/* Left Navbar */

/* right side */
.rightArea{
  background: #f0f1f3;
  min-height: 100vh;
}
.HeaderTop{
  padding: 0 0px 0 0;
  box-shadow: 2px 3px 5px #c5c5c5;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1050;
}
.logoimgs{
  max-width: 100%;
  padding: 14px 0 14px 12px;
}
.logoimgs2{
  max-width: 100%;
  padding: 2px 0 0px 12px;
}
.logoimgsnew{
  width: 60%;
  padding: 38px 0 14px 0px;
  margin-left: -80px;
}
.candidateButton{
  background: #fff !important;
  border: none !important;
  color: #000 !important;
}
.candidateButton:focus{
  outline: 0 !important;
}
.user{
  font-size: 39px !important;
  color: #042954;
}
.boxWithIcon img{
  max-width: 100%;
  margin: 10px 0 0 0;
}
.boxWithIcon{
  width: 150px;
  height: 150px;
  border: 8px solid #174f8f;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  transition: 1s;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 30%), 0 15px 0 rgb(0 0 0 / 1%)
}
.buttonBottomTxt{
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
}
.cricleBg{
  text-align: center;
}
.boxWithIcon:hover{
  background: rgba(255, 255, 255, 0.05);
    -webkit-transform: scale(0.93);
    -moz-transform: scale(0.93);
    -ms-transform: scale(0.93);
    transform: scale(0.93);
    box-shadow: 0 0 0 2px rgba(192, 192, 192, 0.1), 0 0 10px 10px #e2e2e2, 0 0 0 10px rgba(231, 222, 222, 0.5);
    -webkit-transform: scale(0.9);
    opacity: 2;
    background: #f4f9ff;
}
.OrderStatus{
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
}
.pendingstatus{
  background: #ffbc5c;
  padding: 7px 15px;
  text-align: center;
  color: #0e0e0e;
  border-radius: 34px;
  width: 150px;
}
.paymentS{
  background: #01ec3c;
  padding: 7px 15px;
  text-align: center;
  color: #0e0e0e;
  border-radius: 34px;
  width: 150px;
}
.backDivButton{
  background: #fff;
  padding: 84px 15px;
  margin: 69px 15px 0 15px;
  border: 2px solid #b4d4f8;
  position: relative;
}
.backDivOrder{
  background: #fff;
  padding: 30px 15px;
  margin: 30px 15px;
}
.tableOrderList{
  white-space: nowrap;
}
footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background: #174f8f;
  text-align: center;
}
.theadHeader{
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
  background: #ededed;
}
.pendingButton{
  background: #f7f7f7;
  border-radius: 31px;
  display: inline-block;
  padding: 5px 21px;
  width: 146px;
  text-align: center;
}
.Attempted{
  background: #f7f7f7;
  border-radius: 31px;
  display: inline-block;
  padding: 5px 21px;
  width: 146px;
  text-align: center;
}
.AttemptedIcon{
  color: #04a12b;
}
.ReportSubminu{
  background: rgb(48 126 217);
  padding: 15px 15px;
  color: #fff;
  font-size: 17px;
  text-align: left;
  border-bottom: 1px solid #999;
  cursor: pointer;
}
.ReportSubminu:hover{
  background: rgb(27, 113, 211);
}
.secondSection{
    padding: 30px 0;
    margin: 30px 15px;
}
.accountBg{
  display: grid;
  grid-template-columns: 5fr 10fr;
  background: #fff;
  padding: 30px 10px;
  margin: 10px 0;
}
.imgIcon{
  max-width: 100%;
  margin: 10px;
}
.imgIcon:hover{
opacity: 0.8;
}
.TextAreaRight{
  text-align: right;
}
.textHeading{
  font-size: 17px;
  color: #000;
  margin: 8px 0 0 0;
}
.button1Account, .button2Account, .button3Account{
  background: rgb(56 181 116);
  padding: 5px 15px;
  font-size: 16px;
  width: 80px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  margin: 15px 0;
}
.button2Account{
  background: rgb(63 122 252);
}
.button3Account{
  background: rgb(255 160 1);
}
.logoimgs{
  max-width: 100%;
  padding: 14px 0 0px 12px;
}
.rightcolm{
  text-align: right;
  padding: 5px 0 0 0;
}
.newSwaLogo{
  max-width: 100%;
  padding: 10px 0 14px 12px;
  max-height: 70%;
}
.logoDiv{
  text-align: left;
  background: #6c757d;
  width: 289px;
  margin-left: -12px;
}

.logoNewDiv{
  text-align: center;
  width: 289px;
}
.logoSchool{
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoImg{
  width:30%;
  margin-top: 1px;
}
.clear{
  clear: both;
}
.UserCol{
  float: right;
}

.UserColNew{
  float: right;
  margin-top: 25px;
}
.LeftContainer{
  background: #fff;
  padding: 15px;
  margin: 15px;
  margin-left: 303px;
}
.BkgenerateExam{
  background: rgb(255 255 255);
  padding: 20px 10px;
  margin: 20px 0;
  margin-left: 307px;
  animation: fadeInDown 1.5s;
}
.HeadingG img{
  width: 50px;
    background: #fff;
    border: 1px solid;
    border-radius: 50%;
    margin: 0 10px 0 0;
}
.HeadingG{
  text-align: left;
  border-bottom: 1px solid rgb(216, 213, 213);
  padding: 5px;
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
}
.boxesIcon{
  background: #fff;
  padding: 10px;
  box-shadow: -1px 3px 15px #d7d5d5;
  max-width: 300px;
  margin: 10px auto;
  border-radius: 10px;
  text-align: center;
  transition: 1s;
  cursor: pointer;
  border-left: 5px solid #a8d2ff;
}
.boxesIcon:hover{
  box-shadow: 0px 1px 1px #d7d5d5;
  transition: 1s;
}
.boxesIcon:focus{
   background: #f9f9f9;
}
.generatePrcticeSection{
  padding: 47px 0% 0 5.5%;
  transition: 5s;
  
}
.SectionLabel{
  color: #777;
  padding: 9px 17px;
  border-radius: 4px;
  margin: 20px 20px;
  font-size: 16px;
  display: inline-flex;
  background: linear-gradient(#f5fcff, #f0f0f0);
  cursor: pointer;
  border: 1px solid #e6e6e6;
}
.SectionLabel input[type="radio"] {
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 50px;
  height: 25px;
  border: 0.15em solid currentColor;
  border-radius: 50%;
}
.slectTag{
    width: 100%;
    height: 44px;
    border:none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 4px;
    margin: 6px 0;
    background: #fff;
    padding: 0 10px;
}
.slectTag:focus{
  outline: none;
  border-bottom: 1px solid #c0bfbf;
}
.formBgA{
    margin: 2% 0 0 1.6%;
    background: #efefef;
    padding: 15px;
}
.inputHolder{
  margin: 15px 0;
}
a{
  text-decoration: none !important;
}
.schLogoDiv{
  /* width: 150px;
  margin: 0 auto; */
}
@media (min-width:20) and (max-width:800x) {
  .adminDashboard_leftNavigation__v_KsJ {

    width: 53px !important;

}
}
/* right side */


/*manage Student*/
.bg{
  height: calc(100vh - 66px);
  background: #f0f1f3;
}
.btnNavBar{
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #C4C5C6;
  background-color: #F0F1F3;
  margin: 0px !important ;
    /* grid-template-columns: repeat(4, 1fr); */
  /* position: fixed;
  left: 0;
  right: 0; */
}
.finder{
  align-items: center;
}
.searchBarBox{
  background: #fff;
  border-radius: 4px;
  padding: 4px;
}
.addStudent{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.searchIcon{
  /* padding: 2px; */
  color: #9ea0a2;
  margin-top: 2px;
}
.addStudent input{
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  margin-right: 2px;
}
.backbutton{
  text-align: right;
}
.backLinkDash{
  background: #0d6efd;
  color: #fff;
  display: inline-block;
  padding: 6px 15px;
  border-radius: 3px;
  cursor: pointer;
}

.addStudForm{
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  padding: 10px;
  margin: 2% 20% 0% 20%;

}
.inputBox input{
  width: 100%;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
}

.saveBtn{
  text-align: center;
  padding: 20px;
}
.studentList{
  padding: 20px;
  animation: fadeInDown 1s;
}
.addStuList{
  width: 100%;
  margin: auto;
  overflow-x: scroll;

}
.addStuList tr th{
  background: #0d6efd;
  padding: 10px;
  color: #fff;
}
.addStuList tr{
  background: #fff;
  border-bottom: 1px solid gray;
  padding: 10px;
}
.addStuList tr:hover{
  background: rgb(209, 209, 209);
}
.addStuList tr td{
  padding: 6px 10px;
}
.stuListBtn{
  font-size: 20px  !important;
}
.AcademicYear{
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  padding: 10px;
  margin: 0 20%;
}
.addStudentSec{
  animation: fadeInDown 1s;
}
.setWidth{
  width: 80px;
}
.dashboardButton{
  color: #fff !important;
  font-size: 30px !important;
}
.ActiveButton {
  background: #183e6a !important;
  display:block;
  }
  .schoolName{
    position: absolute;
    text-align: center;
    z-index: 9;
    top: -2%;
    left: 50%;
    transform: translate(-49%, -50%);
}
.schoolNameBox{
  display: inline-block;
  text-align: center;
  background: #fff;
  padding: 10px 40px;
  border-radius: 50px;
  border: 2px solid #b4d4f8;
  font-size: 18px;
  font-size: 20px;
}
.inner_boxes{
  background: #fff;
  padding: 15px;
  margin: 4px 10px 0 312px;
  animation: fadeInDown 1s;
}
.heading{
  text-align: left;
  margin: 16px 0 0 0;
  font-size: 18px;
}
.selectsubjectBg{
  padding: 8px;
}
.inputFiled input{
  border-radius: 0 4px 4px 0;
}
.inputFiled{
  display: flex;
  justify-content: right;
}
.searchIcons{
  font-size: 38px !important;
  background: #747577;
  color: #fff;
  margin: 0px 0px 0 0;
  border-radius: 4px 0 0 4px;
  padding: 4px 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.TableManageT{
  white-space: nowrap;
}

.TableManageT input:checked + .slider {
  background-color: #0BB916;
}

.TableManageT input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.TableManageT input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.editButton:hover{
  background: #04a12b;
  color: #fff;
}
.editButton{
  background: rgb(240, 240, 240);
  color: rgb(32, 128, 3);
  padding: 1px;
  border-radius: 4px;
  font-size: 31px !important;
  border:1px solid rgb(32, 128, 3);
  cursor: pointer;
}
.delete:hover{
  background: #f04d1b;
  color: #fff;
}
.delete{
  background: rgb(240, 240, 240);
  color: rgb(233, 60, 48);
  padding: 1px;
  border-radius: 4px;
  font-size: 31px !important;
  border:1px solid rgb(233, 60, 48);
  cursor: pointer;
}
.AddStudentBk{
  background: rgba(8, 8, 8, 0.192);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  overflow: auto;
}
.innerBox{
  max-width: 400px;
  height: 100%;
  background: #fff;
  animation: fadeInDown 0.9s;
  overflow: auto;

}
.addstuHeading{
  background: rgb(36, 152, 255);
  padding: 9px 0 9px 20px;
  color: #fff;
  font-size: 21px;
}
.addstuForm{
  padding: 15px;
}
.addStuForm{
  background: rgb(238, 238, 238);
  padding: 10px 10px;
  margin: 20px 0;
  border-radius: 5px;
}
.addstuForm input{
  border-left:5px solid rgb(201, 201, 201);
}
.addstuForm select{
  border-left:5px solid rgb(201, 201, 201);
}
.footerbuttonRow button{
  margin: 0 10px 0 0;
}
.SchoolRegisterBk{
  background: rgba(8, 8, 8, 0.192);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}
.AllocateSubjectList{
  background: #00000040;
  position: fixed;
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}
.allo_subjact{
  max-width: 400px;
  height: 100%;
  background: #fff;
  animation: fadeInDown 0.9s;
  overflow: auto;
}
.AlocateSubjectFormBk{
  padding: 10px 6px;
  background: #fff;
  border: 1px solid #f3f3f3;
  margin: 10px;
  box-shadow: 2px 3px 4px #ededed;
}
.AllocateSubForStu{
  background: #00000040;
  position: fixed;
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}
.rowbcks{
  background: #f7f7f7;
  padding: 18px 10px;
  border-radius: 2px;
  animation: fadeInLeft 0.5s;
}
.popupBase{
  background: #0e0e0e62;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  overflow: auto;
  }
  .checkboxBgs label:hover{
    background: rgb(233, 233, 233);
  }
  .checkboxBgs label{
    width: 100%;
    background: #fff;
    padding: 8px 2px;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    cursor: pointer;
  }
  .checkboxBgs input{
    width: 29px;
    height: 24px;
    margin: 0 8px 0 0;
  }
  .buttoninFooter{
    margin: 10px 0 0 0;
  }
  .buttoninFooter button{
    margin: 0 10px 0 0;
  }
@keyframes fadeInDown {
  0%{
      opacity: 0;
      transform: translateY(-500px);
  }
  100%{
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  0% {
     opacity: 0;
     transform: translateX(-20px);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
} 

.practicesSetSectionsQbank{
  background: #0e0e0e62;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  overflow: auto;
}
.practicesSetSections{
  background: #0e0e0e62;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headingTxt{
  font-size: 18px;
  color: #000;
}
.innerPopup{
  width: 500px;
  margin: 10px;
  padding: 15px;
  border-radius: 4px;
  background: #fff;
  text-align: center;
  animation: fadeInDown 1s;
  position: relative;
  
}
.questionMark{
  font-size: 78px !important;
  color: #6b6c6c;
  background: #f0f0f0;
  border-radius: 50%;
  padding: 10px;
  margin: 20px 0;
}
.buttonSections button{
  margin: 15px 10px;
  width: 207px;
  font-size: 16px;
  text-transform: uppercase;
}
.innerPopupBignSize{
  margin: 10px;
  border-radius: 4px;
  background: #fff;
  animation: fadeInDown 1s;
  position: relative;
  width: 100wh;
  min-height: 97vh;
  overflow: auto;
}
.headingBankname{
  background: #0d6efd;
  padding: 10px;
  font-size: 21px;
  color: #fff;
  position: relative;
}
.buttonClose{
  position: absolute;
  right: 0.3%;
  top: 11%;
}
.sectiondiv{
  margin: 30px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionbox{
  background: rgb(221, 221, 221);
  padding: 8px 30px;
  font-size: 20px;
  border-radius: 20px;
  margin: 0 10px;
  cursor: pointer;
  border:1px solid #999

}
.sectionbox:hover{
  background: rgb(194, 194, 194);
  color: rgb(0, 0, 0);

}

.PracSetHeading:hover{
  background: #33cc99;
  }
  .PracSetHeading:hover .iconPrac img{
    background: #33cc99;
    }
  .Setsback{
    position: relative;
    height: 135px;
    max-width: 200px;
    margin: 29px auto;
    cursor: pointer;
  
  }
  .PracSetHeading{
    background: #9d71f5;
    height: 68px;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    font-size: 21px !important;
    display: inline-block;
    padding: 34px 0 0 0;
  
  }
  .Setsback:hover .PracSetHeading{
    background:  #33cc99;
  }
  .Setsback:hover img{
    border: 2px solid #33cc99;
  }
  .iconPrac img{
    position: absolute;
    top: 25%;
    left: 50%;
    border: 2px solid #ac83ff;
    width: 66px !important;
    height: 66px;
    border-radius: 50%;
    background: #fff;
    transform: translateY(0) translateX(-50%);
    z-index: 1;
    padding: 10px;
  }
  .PracSetHeading{
    font-size: 17px;
    margin: 26px 0 0 0;
  }
  .P_ButtonRow{
    margin: 20px;
    background: rgb(245, 245, 245);
    animation: fadeInLeft 0.5s;
  }
  .formTimes{
    background: #fff;
    margin: 20px 10px;
    padding: 20px 10px;
  }
  .selectTimesRow{
    margin: 30px 15px;
    background: #fafafa;
    padding: 15px 10px;
    max-width: 100%;
  }
  .formSelectTimes label{
    text-align: left;
  }
  .formSelectTimes{
    margin: 10px;
  }
  .buttonFooter{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .learnBtn:hover .SubjContainer{
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
  }
  
  .learnBtn:hover .logoCircle{
    border: 2px solid #9966FF;
  }
  .learnBtn:hover .logoCircle img{
    transform: rotate(10deg) scale(1.05);
  }
  .learnBtn:hover .headingDiv{
    background: #9966FF;
  }
  .learnBtn:hover .dropArrow{
    color: #9966FF !important;
  }
  
  .learnBtn{
    position: relative;
    padding: 20px;
    cursor: pointer;
    max-width: 315px;
    margin:  auto;
  }
  .logoCircle{
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #1B60CC;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    left: 0;
  }
  .logoCircle img{
    width: 100%;
    transition: transform .7s;
  }
  .headingDiv{
    background: #1B60CC;
    padding: 10px 4px 10px 48px;
    border: 2px solid #fff;
    border-radius: 50px;
    max-width: 275px;
    color: #fff;
    text-align: center;
    font-size: 15px;
  }
  .headingwithBack{
    padding: 5px 10px;
    background: #f2f2f2;
    border-bottom: 1px solid #d9d9d9;
  }
  .backSection{
    animation: fadeInLeft 0.5s;
    text-align: center;
    margin: 15px 0;
  }
  .sectionbox:hover{
    background: #bdbdbd;
  }
  .sectionbox{
    margin: 0 auto;
    background: #ececec;
    padding: 7px 30px;
    font-size: 19px;
    border-radius: 20px;
    z-index: 2;
    cursor: pointer;
    max-width: 153px;
    display: inline-block;
    margin: 10px;
  }
  .AssessmentList table{
    background: #fff;
    white-space: nowrap;
  }
  .AssessmentList{
    padding: 15px;
    background: rgb(247, 247, 247);
    animation: fadeInLeft 0.5s;
  }
  @keyframes fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(-20px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
  } 
  .viewDetailesButton{
    background: #f7f7f7;
    border-radius: 31px;
    display: inline-block;
    padding: 5px 21px;
    max-width: 166px;
    text-align: center;
    cursor: pointer;
  }
  .mainHeading{
    font-size: 17px;
  }
  .viewDetailesPop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #468bf1;
    padding: 15px;
    overflow: auto;
  
  }
  .innerdivReport{
    background: #fff;
    padding: 15px;
    animation: fadeInLeft 0.5s;
    border-radius: 2px;
    position: relative;
  }
  .closeViewDetailes{
    font-size: 40px !important;
    position: absolute;
    right: 24px;
    top: 10px;
    color: #9b9a9a;
    cursor: pointer;
  }
  .headingCol{
    font-size: 18px;
    padding: 10px 0;
  }
  .viewDetailesPop table{
    white-space: nowrap;
  }
  .checkMark{
    color: #0ac037;
    font-size: 30px !important;
  }
  .wrongSign{
    color: #f72d12;
    font-size: 30px !important;
  }
  .ReviewAnswer_section{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: #468bf1;
    padding: 15px;
    overflow: auto;
  }
  .rowGrid{
    display: grid;
    grid-template-columns: 4fr 96fr;
    margin: 4% 10%;
    min-height: 500px;
    font-size: 19px;
  }
  .QuestionNo {
    color: #96f;
    font-weight: 700;
  }
  .optionsRow{
    display: grid;
    grid-template-columns: 5fr 95fr;
  }
  .optionsRow input[type=radio] {
    background-color: #fff;
    font: inherit;
    color: currentColor;
    height: 15px;
    width: 21px;
    border: 0.15em solid #a37af4;
    border-radius: 50%;
    cursor: pointer;
  }
  .colFixed {
    width: 106px;
  }
  .divOne{
    white-space: nowrap;
    font-size: 18px;
    font-weight: 700;
    width: 57px;
  }
  .headingExpl {
    color: #96f;
    font-weight: 700;
    font-size: 19px;
  }
  .explanationBox {
    margin: 1% 10%;
  }
  .expContent {
    font-size: 17px;
    color: #000;
  }
  .questionDiv label {
    margin: 20px 0;
  }
  .selectSchoolbk{
    padding: 15px 10px;
    background: #f0f0f0;
    margin: 20px 0;
  }
  .inputWithSubject{
    border-radius: 3px;
    float: right;
    width: 71px !important;
    height: 35px !important;
    margin: 2px 0 0 0 !important;
    border: 2px solid #9abef7;
  }
  .clear{
    clear: both;
  }
  .buttonInManage{
    background: #ececec;
    padding: 7px 30px;
    font-size: 19px;
    border-radius: 20px;
    z-index: 2;
    cursor: pointer;
    display: inline-block;
    margin: 10px;
  }
  .rowButtons{
    display: flex;
    justify-content: center;
  }
  .SubmitBTN{
    width: 80px;
    border: none !important;
    margin: 0 10px 0 0;
  }
  .showErrbase{
    background: #00000040;
    position: fixed;
    z-index: 9999999999999999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inerBox{
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    width: 400px;
    margin: 10px;
    text-align: center;
    animation: fadeInDown 1s;

  }
  .errIcons{
    font-size: 60px !important;
    color: #ffbc5c;
  }
  .errMessageDiv{
    border-radius: 4px;
    box-shadow: none;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: rgb(253, 237, 237);
    padding: 10px 16px;
    color: rgb(95, 33, 32);
    margin: 23px 0;
  }
  .successIcons{
    font-size: 100px !important;
    color: #90db80;
  }
  .successMes{
    border-radius: 4px;
    box-shadow: none;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: rgb(226, 249, 232);
    padding: 10px 16px;
    color: rgb(42, 140, 39);
    margin: 23px 0;
  }
.bulkUploadPop{
    background: #00000040;
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.innerBox{
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  height: 260px;
  margin: 10px;
  text-align: center;
}
.iconsUpload{
  font-size: 70px !important;
  color: rgb(179, 178, 178);
  margin: 10px 0;
}
.uolpadFilesBulk{
  text-align: left;
}
.buttonSection{
  text-align: center;
  margin: 20px 10px;
}
.buttonSection button{
  margin: 2px 10px;
}
.innerSidePop{
  background: #fff;
  width: 500px;
  padding: 10px;
  animation: fadeInDown 1s;

}
.heading2{
  font-size: 18px;
  color: #000;
}
.subjectGroups{
  animation: fadeInLeft 1s;
  background: rgb(242, 242, 242);
  padding: 10px;
}
.sectionOfCombobox{
  animation: fadeInLeft 1s;
  padding: 20px 10px;
  background: #f5f5f5;
}
.gnerateBtn{
  margin: 22px 0 0 0;
}
.basePopConfirm{
  background: rgba(0, 0, 0, 0.212);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.innerWhiteBox{
  width: 500px;
  padding: 15px;
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  text-align: center;

}
.iconAreYouSure{
  font-size: 70px !important;
  margin: 20px 0;
  color: #4ba4f7;
}
.messageTxt{
  font-size: 18px;
  color: #000;
}
.onscrollFUpdateUsr{
  position: sticky;
  right: 0;
  z-index: 1;
  background: #f2f2f2 !important;
}
.onscrollFUpdateUsrD{
  position: sticky;
  right: 0;
  z-index: 1;
  background: #ffffff !important;
}
.onscrollFAssin{
  position: sticky;
  right: 47px;
  z-index: 1;
  background: #f2f2f2 !important;
}
.rowFixbuttonsec{
  margin: 10px 0;
  position: sticky;
  bottom: 5px;
}
.sectionboxX{max-width: fit-content;}

.partSectionRow{
  text-align: center;
  animation: fadeInLeft 0.5s;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonsPart{
  color: #fff;
  text-transform: uppercase;
  padding: 9px 10px;
  width: 176px;
  text-align: center;
  border-radius: 0 2px 15px 15px;
  margin: 10px;
  background: #9465f2;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  border-left: 3px solid #3e103b;
  border-bottom: 3px solid #3e103b;
}
.headingDiv2{
  background: #1B60CC;
  padding: 10px 4px 10px 48px;
  border: 2px solid #fff;
  border-radius: 50px;
  max-width: 275px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  margin-top: -11px;
}












/* media Query */
@media screen and (max-width: 991px) {
  .addStudForm {
    grid-template-columns: 1fr;
    margin: 0;
  }
  .AcademicYear{
    margin: 0;
  }
  .inputBox input{
    min-width: 100%;
  }
  .addStudForm{
    grid-gap: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width:462px)  {
  .AcademicYear{
    grid-template-columns: 1fr 2fr;
    
  }  
}


/*manage Student*/