.container{
    background-image: url(../../assets/loginBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginDiv{
    max-width: 883px;
    background: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 20px;
}
.loginForm input{
    outline: none;
}
.loginForm{
    padding: 40px;
    z-index: 2;
}
.LoginH{
    text-align: center;
    color: #4D4D4D;
    margin-top: 0;
    margin-bottom: 20px;
}
.lableHead{
    font-family: 'Calibri';
    font-size: 20px;
    font-weight: bold;
    color: #4D4D4D;
    margin-top: 40px;
}
.inputRow{
    display: grid;
    grid-template-columns: 1fr 10fr;
    border-bottom: 1px solid #bbb;
    margin-top: 20px;
    margin-bottom: 50px;
}
.icon{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #042954;
}
.inputRow input{
    font-family: 'Calibri';
    font-size: 18px;
    width: 100%;
    height: 30px;
    border: none;
}
.logButton:hover{
    background: #003399;
}
.logButton{
    background: #042954;
    color: #fff;
    position: relative;
    width: 150px;
    padding: 10px;
    border-radius: 29px;
    font-size: 19px;
    display: block;
    margin: 20px auto;
    cursor: pointer;
}
.logButton:after {
    content: "";
    width: 116px;
    height: 5px;
    background: #0cf;
    background: linear-gradient(0deg,rgba(0,204,255,0),hsla(0,0%,100%,.594));
    position: absolute;
    top: 2px;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 20px;
}
.forText{
    text-align: center;
}
.forgetPass:hover{
    color: #A71234;
    text-decoration: underline;
}
.forgetPass{
    color: #042954;
}

.loginImg{
    background-image: url(../../assets/loginImg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
}
.lognImg img{
    width: 100%;
}
/* animation css */
.context {
    top:50vh;
  }
    .context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
  }
  .area{
    background: #4e54c8;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    height:100%;
  }
  .circles{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }
  .circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }
  .circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  .circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  .circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  .circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  .circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  .circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  .circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  .circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  .circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  .circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
  .erroBox{
    animation: fadeInDown 1s;
    margin-bottom: 0 !important;
  }
  .dropdownItem{
    padding: 4px 10px;
  }
  
  @keyframes animate {
  
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
  
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
  
  }
/* animation css */

@media screen and (max-width: 768px) {
    .loginDiv{
        grid-template-columns: 1fr;
        margin: 0 10px;
    }
    .loginForm{
        padding: 20px;
    }
    .loginImg{
        display: none;
    }

}