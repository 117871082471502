/* Left Navbar */
.leftNavigation {
  left: 0;
  width: 289px;
  background: #174f8f;
  height: 100%;
  transition: 1s;
  overflow: hidden;
  white-space: normal;
  z-index: 911;
  top: 66px;
  float: left;
}

.headerLeft {
  background: #a37af4;
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 3fr 10fr 2fr;
}

.colm {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  margin: 13px 0 0 6px;
}

.ActiveButton {
  background: #183e6a !important;
  display: block;
}

.leftButton:hover {
  border-bottom: 1px solid #006deb;
}

.DashBoardIcon {
  font-size: 29px;
  color: #fff;
  margin: 0 18px 0 0;
  cursor: pointer;
}

.leftButton {
  background-size: 200%;
  transition: .5s ease-out;
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 2fr 10fr;
  cursor: pointer;
  border-bottom: 1px solid #4e94e5;
}

.iconLeft {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 2px;
  background: #042954;

}

.iconLeft img {
  max-width: 100%;
  background: #fff;
  border-radius: 50%;
}

.leftText {
  font-size: 16px;
  color: #fff;
  text-align: left;
  margin: 4px 0 0 6px;
}

/* Left Navbar */

/* right side */
.rightArea {
  background: #fff;

  min-height: 100vh;
  transition: 1s;
  background-size: 100%;
  position: relative;

  /* background: linear-gradient(-45deg, #faf9f8, #c0c2df, #f7f7f7, #f5f5f5); */
  /* animation: gradient 15s ease infinite; */
}

.HeaderTop {
  box-shadow: 2px 3px 5px #343434;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 999999;
}

.HeaderTop2 {
  padding: 0 0px 0px 0;
  box-shadow: 2px 3px 5px #343434;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 999999;
}

.logoimgs {
  max-width: 100%;
  max-height: 70%;
}

.logoimgs2 {
  max-width: 100%;
  padding: 14px 0 0px 12px;
  max-height: 70%;
}

.logoSchool {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newSwaLogo {
  max-width: 100%;
  padding: 10px 0 14px 12px;
  max-height: 70%;
}

.logoimgsnew {
  width: 60%;
  padding: 21px 0 14px 0px;
  margin-left: -80px;
}

.candidateButton {
  background: #fff !important;
  border: none !important;
  color: #000 !important;
}

.candidateButton:focus {
  outline: 0 !important;
}

.user {
  font-size: 39px !important;
  color: #042954;
}

.boxWithIcon img {
  max-width: 100%;
  margin: 10px 0 0 0;
}

.boxWithIcon {
  width: 150px;
  height: 150px;
  border: 8px solid #174f8f;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  transition: 1s;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 30%), 0 15px 0 rgb(0 0 0 / 1%)
}

.buttonBottomTxt {
  font-size: 19px;
  color: #ffffff;
  margin: 21px 0;
  padding: 5px 0;
  text-align: center;
  border-radius: 7px;
  background: #728e9c;
  text-shadow: 1px 2px 1px #444;
}

.cricleBg {
  text-align: center;
}

.boxWithIcon:hover {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 0 2px rgba(192, 192, 192, 0.1), 0 0 10px 10px #e2e2e2, 0 0 0 10px rgba(231, 222, 222, 0.5);
  opacity: 2;
  background: #f4f9ff;
}

.OrderStatus {
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
}

.pendingstatus {
  background: #ffbc5c;
  padding: 7px 15px;
  text-align: center;
  color: #0e0e0e;
  border-radius: 34px;
  width: 150px;
}

.paymentS {
  background: #01ec3c;
  padding: 7px 15px;
  text-align: center;
  color: #0e0e0e;
  border-radius: 34px;
  width: 150px;
}

.containerBox {
  background: url(../../assets/dashbg.png);
  background-attachment: fixed;
  background-size: cover;
  min-height: 91vh;

}

.timerDiv {
  position: absolute;
  right: 24px;
  font-size: 20px;
  background-color: #fff;
  padding: 3px 20px;
  border-radius: 20px;
  top: 23%;
}

.backDivButton {
  padding: 3% 0 0 0;


}

.backDivOrder {
  background: #fff;
  padding: 30px 15px;
  margin: 30px 15px;
}

.tableOrderList {
  white-space: nowrap;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background: #174f8f;
  text-align: center;
}

.theadHeader {
  font-size: 16px;
  color: #ffffff;
  margin: 10px 0;
  background: #174f8f;
}

.pendingButton {
  background: #f7f7f7;
  border-radius: 31px;
  display: inline-block;
  padding: 5px 21px;
  width: 146px;
  text-align: center;
}

.Attempted {
  background: #f7f7f7;
  border-radius: 31px;
  display: inline-block;
  padding: 5px 21px;
  width: 146px;
  text-align: center;
}

.AttemptedIcon {
  color: #04a12b;
}

.UnAttempted {
  background: #f7f7f7;
  border-radius: 31px;
  display: inline-block;
  padding: 5px 21px;
  width: 146px;
  text-align: center;
}

.UnAttemptedIcon {
  color: #a10433;
}

.selectSection {
  background: #fff;
  padding: 30px 15px;
  margin: 30px 15px;
  transition: 6s;
}

.selectCombobox:focus {
  outline: none;
}

.selectCombobox {
  padding: 5px 2px;
  height: 48px;
  width: 258px;
  border: 1px solid #e1e1e1;
  border-radius: 4px 0 0 4px;
  background: #eff5fb;
}

.oneoptionDisable {
  display: none;
}

.nextButton {
  background: #25aaf7;
  height: 49px;
  border: 0;
  width: 60px;
  color: #fff;
  border-radius: 0 4px 4px 0;
}

.notesBox {
  background: #d5d5d5;
  width: 100%;
  height: 500px;
  margin: 15px 0;
  -webkit-font-smoothing: antialiased;
  transition: 1s;
}

.rightcolm {
  text-align: right;
  padding: 5px 0 0 0;
}

.logoDiv {
  text-align: left;
  background: #a37af4;
  width: 289px;
}

.logoNewDiv {
  text-align: left;
  background: #fff;
  width: 289px;
}

.schLogoDiv img {
  /* width: 132px; */
  /* height: auto; */

}

/* .schLogoDiv2 img{
    height:85px;
    width: 112px;
  } */
.schLogoDiv img {
  max-width: 300px;
}

.schLogoDiv {}

/* .schLogoDiv2{
    text-align: center;
    position: absolute;
    margin: 0 auto;
    left: 41%;
    transform: translateY(-50%);
    top: 41%;
  } */
.tbodyTable {
  font-size: 16px;
}

.forLessPadding {
  padding-left: 0 !important;
}

.forPaddingCol {
  padding: 0px;
}

.logoCol {
  float: left;
}

.clear {
  clear: both;
}

.UserCol {
  float: right;
}

.UserColNew {
  float: right;
  margin-top: 10px;
}

.LeftContainer {
  background: #fff;
  padding: 15px;
  margin: 15px;
  margin-left: 303px;
}

.backDiv {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.Backbody {
  background: #f0f1f3;
  min-height: 100vh;
  transition: 1s;

}

.backdiv {
  background: #fff;
  margin: 15px;
  padding: 15px;
  margin-left: 303px;
  animation: fadeInDown 1s;

}

.headingPractice {
  font-size: 19px;
  color: #084a95;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.RowLabel {
  font-size: 16px;
  background: #f7f7f7;
  padding: 6px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px 0 0;
}

.TopicWisePractice {
  padding: 10px 8px;
  animation: fadeInLeft 1s;
}

.TopicWisePractice input,
select:focus {
  outline: none;
}

.TopicWisePractice input {
  width: 100%;
  padding: 5px 5px;
  background: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  margin: 7px 1px;
}

.TopicWisePractice select {
  width: 100%;
  padding: 5px 5px;
  background: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  margin: 7px 1px;
  height: 43px;
  box-shadow: 1px 1px 3px #e1e1e1;
}

.TopicWisePractice label {
  font-size: 16px;
  color: #042954;
}

.maxSmallText {
  font-size: 14px;
}

.RowCOlm {
  column-gap: 10px;
}

.buttonNext {
  background: #042954;
  border: 2px solid #a37af4;
  border-radius: 36px;
  color: #fff;
  padding: 5px 38px;
  font-size: 21px;
  margin: 29px 0 0 0;
  box-shadow: 0px 0px 0px #042954;
  font-weight: bold;
}

.RightBackbtn {
  justify-content: flex-end;
  display: flex;
}

.backbuttonIcon {
  font-size: 34px;
}

.backbutton:hover {
  background: #dfdfdf;
}

.backbutton {
  background: #efefef;
  color: #2a2929 !important;
  width: 102px;
  font-size: 19px;
  padding: 4px 0;
  text-align: center;
  height: 40px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px #e7e7e7;
  cursor: pointer;
}

.MainMcqBk {
  background: url(../../assets/loginBg.png);
  min-height: 100vh;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainWrappr {
  background: #fff;
  position: relative;
  max-width: 95%;

}

.QuestionNo {
  color: #9966ff;
  font-weight: bold;
}

.colFixed {
  width: 120px;
}

.rowGrid {
  display: grid;
  grid-template-columns: 4fr 96fr;
  margin: 4% 10%;
  min-height: 500px;
  font-size: 19px;
}

.HeaderPracticeAss {
  background: #9966ff;
  padding: 10px 0 10px 20px;
  display: grid;
  grid-template-columns: 4fr 90fr;
  position: relative;
}

.logoPracticeIcon img {
  max-width: 100%;
}

.PracticeText {
  font-size: 22px;
  color: #fff;
  margin: 13px 0 0 0;
  font-weight: bold;
}

.logoPracticeIcon {
  width: 60px;
  height: 60px;
  border: 3px solid #174f8f;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  transition: 1s;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 30%), 0 15px 0 rgb(0 0 0 / 1%);
}

.QuestionNavigation {
  background: #e6e6e6;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  text-align: center;
}

.SpaceVar {
  height: 10px;
}

.optionsRow {
  display: grid;
  grid-template-columns: 5fr 95fr;
}

.optionsRow input[type="radio"] {
  background-color: #fff;
  font: inherit;
  color: currentColor;
  height: 15px;
  width: 21px;
  border: 0.15em solid #a37af4;
  border-radius: 50%;
  cursor: pointer;
}

.divOne {
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  width: 57px;
}

.questionDiv label {
  margin: 20px 0;
}

.nextQuestion {
  background: #04adf0;
  border: 2px solid #3c4180;
  border-radius: 36px;
  color: #fff;
  padding: 5px 38px;
  font-size: 21px;
  margin: 0px 0 0 10%;
  box-shadow: 0px 0px 0px #042954;
  font-weight: bold;
}

.navBarNoQues {
  background: #d3bcff;
  width: 65px;
  height: 100%;
  height: 480px;
  border-left: 3px solid #9966ff;
  overflow: auto;
}

.queNo {
  background: #fff;
  color: #000;
  border-radius: 0 10px 10px 0;
  width: 43px;
  text-align: center;
  margin: 15px 0 0 0;
  font-weight: bold;
  padding: 4px 0;
  display: inline-block
}

/* right side */

/* Practice sets */
.PracSetHeading:hover {
  background: #33cc99;
}

.PracSetHeading:hover .iconPrac img {
  background: #33cc99;
}

.Setsback {
  position: relative;
  height: 135px;
  max-width: 200px;
  margin: 0 auto;
  cursor: pointer;

}

.PracSetHeading {
  background: #9d71f5;
  height: 68px;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 21px !important;
  display: inline-block;
  padding: 34px 0 0 0;

}

.Setsback:hover .PracSetHeading {
  background: #33cc99;
}

.Setsback:hover img {
  border: 2px solid #33cc99;
}

.iconPrac img {
  position: absolute;
  top: 25%;
  left: 50%;
  border: 2px solid #ac83ff;
  width: 66px !important;
  height: 66px;
  border-radius: 50%;
  background: #fff;
  transform: translateY(0) translateX(-50%);
  z-index: 1;
  padding: 10px;
}

.PracSetHeading {
  font-size: 17px;
  margin: 26px 0 0 0;
}

.linksButton {
  display: block;
}

.linksButton:focus {
  background: #174f8f;
}

.stuNotes {
  max-width: 100%;
}

/* Practice sets */

.navigationInner {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.navigationInner::before {
  content: "";
  width: 100%;
  height: 2px;
  background: #9966ff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
}

.ButtonNext_pre {
  background: #9966ff;
  padding: 9px 19px;
  font-size: 19px;
  border-radius: 7px;
  z-index: 9;
  color: #fff;
  cursor: pointer;
  position: sticky;
  left: 0;
  z-index: 9;
}

.QuestionDiv {
  overflow-x: auto;
  margin: 0 20px;
  width: 100%;
  display: flex;
  z-index: 1;
  cursor: pointer;
}

.qNoCricle:hover {
  background-image: linear-gradient(#9966ff 46%, #9966ff 50%);
}

.qNoCricle {
  z-index: 999999999999;
  border-radius: 50%;
  background-image: linear-gradient(white 46%, #9966ff 50%);
  margin: 15px;
  font-size: 23px;
  font-weight: bold;
  padding: 3px;
}

.ButtonNext {
  background: #9966ff;
  padding: 9px 19px;
  font-size: 19px;
  border-radius: 7px;
  z-index: 9;
  color: #fff;
  cursor: pointer;
  position: sticky;
  right: 0;
  z-index: 9;
}

.innerContainer {
  max-width: 100%;
  overflow-x: auto;
}

.innerCricle {
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 2px;
}

.PracticeSubmit {
  border-radius: 50px;
  padding: 5px 30px;
  color: #fff;
  background: #042954;
  font-size: 22px;
  border: none;
  margin: 10px 0;
}

.PracticeSubmit:hover {
  background: #2782ed;
}

.questionDiv img {
  max-width: 100% !important;
}

.radiobtnAttem {
  margin: 0 !important;
  padding: 0px !important;
  font-weight: bold;
}

.stuDashboard_divOne svg {
  padding: 0 !important;
}

.dashboardButton {
  color: #fcfcfc;
  font-size: 31px !important;
}

.buttonBg {
  position: fixed;
  top: 50%;
  z-index: 99999;
  right: -157px;
}

.button1:hover {
  background: linear-gradient(rgb(28, 83, 167), rgb(6, 82, 126));
  transition: 2s;
}

.button1:hover {
  right: 0px;
}

.button1 {
  background: linear-gradient(rgb(16 111 253), rgb(13 158 243));
  padding: 10px;
  border-radius: 47px 0 0 45px;
  color: #f3f3f3;
  border: 1px solid #d1d1d1;
  font-size: 19px;
  cursor: pointer;
  position: fixed;
  right: -157px;
  transition: 2s;
}

.addMoreSubjectBg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.generalInstructionBg {
  background: #fff;
  width: 100%;
  right: 0;
  animation: stuDashboard_fadeInDown__1xgpI 1s;
  box-shadow: -6px 22px 21px #04040442;
  margin: 80px;
  max-height: calc(100% - 160px);
  min-height: calc(100% - 160px);
}

.addMoreSubjectBg_instruction {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 999;
}

.generalInstructionBg_instruction {
  background: #fff;
  width: 100%;
  right: 0;
  animation: stuDashboard_stuDashboard_stuDashboard_fadeInDown__1xgpI__vN53j__3meee 1s;
  box-shadow: -6px 22px 21px #04040442;
  margin: 80px;
  max-height: 500px;
  min-height: 500px;
  margin-left: 303px;
  margin-top: 184px;
  margin-right: 15px;
}

.subjectBody_instruction {
  padding: 10px;
  margin: 10px 0;
  overflow-y: scroll;
  height: 436px;
  background-color: #fff;
}

.subjectBody_instruction>h6 {
  text-align: center;
  border-bottom: 1px solid #cecece;
}

.subjectBody {
  background: #fff;
  width: 520px;
  right: 0;
  animation: fadeInDown 1s;
  box-shadow: -6px 22px 21px #04040442;
  margin: 10px;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-500px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.headingBk {
  padding: 11px 10px;
  background: #ebebeb;
}

.crossbutton {
  float: right;
  font-size: 31px !important;
  color: #7e7e7e;
  cursor: pointer;
}

.SectionHeading {
  margin: 2px 0 0 0;
  font-size: 16px;
  font-weight: bold;
}

.rowSection {
  display: grid;
  grid-template-columns: 10fr 10fr;
  margin: 11px 0 0 0;
}

.containerSe {
  display: block;
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  user-select: none;
  padding: 6px 0 7px 50px;
  background: #f7f7f7;
}

/* Hide the browser's default checkbox */
.containerSe input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 7px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #2196f3;
}

/* On mouse-over, add a grey background color */
.containerSe:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerSe input:checked~.checkmark {
  background-color: #2196F3;
  border-radius: 3px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerSe input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerSe .checkmark:after {
  left: 9px;
  top: 3px;
  width: 9px;
  height: 19px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.SubjectList label {
  border-bottom: 1px solid #e7e7e7;
  padding: 1px 0 10px 50px;
}

.headerSltsubject {
  background: #e5e5e5;
}

.SubjectBodyArea {
  padding: 10px;
  margin: 10px 0;
}

.containerSeCheck {
  width: 100%;
  background: #fcfcfc;
  padding: 7px 5px;
  border-bottom: 1px solid rgb(240, 239, 239);
  font-size: 17px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.containerSeCheck input {
  width: 25px;
  height: 25px;
  margin: 0 10px 0 0;
}

.amountBox {
  display: flex;
  justify-content: right;
}

.amountDigit {
  background: #f9f9f9;
  padding: 5px 0;
  font-size: 22px;
  margin: 15px 0 0 0;
  width: 127px;
  text-align: center;
  border-radius: 13px;
  box-shadow: 2px 3px 8px #0202022e;
}

.subjectBox {
  margin: 20px 0;
  background: #f0f0f0;
  padding: 10px;
  animation: fadeInLeft 1s;
  max-height: 250px;
  overflow: auto;
}

.subjectTxt {
  margin: 5px 0;
  font-size: 17px;
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.me {
  width: 400px;
  margin: 90px auto;
}

.me p,
.me h1 {
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
}

.me p {
  font-weight: 200;
}

.me span {
  font-weight: bold;
}

.social {
  position: fixed;
  top: 57%;
  left: 0;
}

.social ul {
  padding: 0px;
  -webkit-transform: translate(-270px, 0);
  -moz-transform: translate(-270px, 0);
  -ms-transform: translate(-270px, 0);
  -o-transform: translate(-270px, 0);
  transform: translate(-270px, 0);
}

.social ul li {
  display: block;
  margin: 5px;
  background: rgb(38 108 189);
  width: 327px;
  text-align: right;
  padding: 5px 10px;
  border-radius: 0 30px 30px 0;
  transition: all 1s;
  color: #fff;
  cursor: pointer;
}

.social ul li:hover {
  -webkit-transform: translate(190px, 0);
  -moz-transform: translate(190px, 0);
  -ms-transform: translate(190px, 0);
  -o-transform: translate(190px, 0);
  transform: translate(190px, 0);
  background: rgba(39, 59, 128, 0.979);
  color: #fff;
}

.social ul li:hover a {
  color: rgb(255, 255, 255);
}

.social ul li:hover i {
  color: #fff;
  background: red;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.social ul li i {
  margin-left: 10px;
  color: #000;
  background: #fff;
  padding: 7px 10px 0 0;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 20px;
  background: #ffffff;
  transform: rotate(0deg);
  display: inline-block;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

a {
  color: #fff;
  text-decoration: none;
}

.ButtonRepost:after {
  content: "";
  width: 80%;
  height: 5px;
  background: #0cf;
  background: linear-gradient(0deg, rgba(0, 204, 255, 0), hsla(0, 0%, 100%, .594));
  position: absolute;
  top: 2px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  border-radius: 20px;
}

.ButtonRepost {
  color: #222;
  background-color: #e7e7e7;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px 0;
  border: none;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.9s;
  text-align: center;
  max-width: 300px;
  margin: 20px auto;
  border-radius: 50px;
  background: linear-gradient(358deg, #4db7f5, #5da4f5);
  color: #fff;
  cursor: pointer;

}

.ButtonRepost:hover {
  background: linear-gradient(358deg, #30adf5, #137af0);
}

.SectionBk:before {
  content: '';
  background-color: #ececec;
  height: 4px;
  width: 10%;
  transform: translateY(-50%);
  position: absolute;
  top: 45%;
  z-index: 1;
}

.SectionBk {
  position: relative;
  display: flex;
  justify-content: center;
  animation: fadeInDown 1s;

}

.buttonSection:hover {
  background: #bdbdbd;
}

.buttonSection {
  margin: 0 15px;
  background: #ececec;
  padding: 7px 30px;
  font-size: 19px;
  border-radius: 20px;
  z-index: 2;
  cursor: pointer;
}

.mainHeading {
  font-size: 18px;
  padding: 15px 15px;
  background: rgb(251 251 251);
  margin: 10px 0;
}

.viewDetailesButton {
  background: hsl(0deg 0% 98%);
  padding: 5px 5px;
  color: #161616;
  border-radius: 5px;
  text-align: center;
  border-radius: 53px;
  cursor: pointer;
  width: 136px;

}

.backSection {
  animation: fadeInLeft 0.5s;
  text-align: center;
  margin: 15px 0;
}

.viewDetailesButtond {
  background: hsl(0deg 0% 98%);
  padding: 5px 5px;
  color: #161616;
  border-radius: 5px;
  text-align: center;
  border-radius: 53px;
  /* cursor: pointer; */
  width: 136px;
  cursor: not-allowed;
}

.AssessmentList {
  animation: fadeInLeft 0.5s;
  margin: 20px 0;
}

.AssessmentList table {
  white-space: nowrap;

}

.headerTr {
  background: #cbe8ff;
  text-align: center;
}

.learnBtn:hover .SubjContainer {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}

.learnBtn:hover .logoCircle {
  border: 2px solid #9966FF;
}

.learnBtn:hover .logoCircle img {
  transform: rotate(10deg) scale(1.05);
}

.learnBtn:hover .headingDiv {
  background: #9966FF;
}

.learnBtn:hover .dropArrow {
  color: #9966FF !important;
}

.logoCircleSelf {
  width: 81px;
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #1B60CC;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 0;
}

.logoCircleSelf img {
  max-width: 68%;
  transition: transform .7s;
}

.headingDivSelf {
  background: #1B60CC;
  padding: 10px 4px 10px 48px;
  border: 2px solid #fff;
  border-radius: 50px;
  max-width: 100%;
  color: #fff;
  text-align: center;
  /* font-size: 17px; */
  font-size: 12px;
}

.learnBtnSelf {
  position: relative;
  padding: 16px;
  cursor: pointer;
  max-width: 339px;
  margin: auto;
}

.learnBtn {
  position: relative;
  padding: 20px;
  cursor: pointer;
  max-width: 315px;
  margin: auto;
}

.logoCircle {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1B60CC;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 0;
}

.logoCircle img {
  width: 100%;
  transition: transform .7s;
}

.headingDiv {
  background: #1B60CC;
  padding: 10px 4px 10px 48px;
  border: 2px solid #fff;
  border-radius: 50px;
  max-width: 275px;
  color: #fff;
  text-align: center;
  font-size: 15px;
}

.headingDiv2 {
  background: #1B60CC;
  padding: 10px 4px 10px 48px;
  border: 2px solid #fff;
  border-radius: 50px;
  max-width: 275px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  margin-top: -11px;
}

.headingwithBack {
  padding: 5px 10px;
  background: #f2f2f2;
  border-bottom: 1px solid #d9d9d9;
}

.viewDetailesPop {
  background: #3284e1;
  padding: 15px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  animation: fadeInDown 1s;
  overflow: auto;
}

.innerdivReport {
  background: #fff;
  min-height: 96.5vh;
}

.headerTp {
  background: rgb(249 249 249);
  padding: 10px;
  display: grid;
  grid-template-columns: 10fr 10fr;
}

.crossiconDiv {
  font-size: 31px !important;
  color: #6c6f71;
  cursor: pointer;
}

.crossIcon {
  text-align: right;
}

.headingCol {
  font-size: 18px;
}

.tableBackDeta {
  margin: 15px;
}

.checkMark {
  font-size: 31px !important;
  color: #04a12b;
}

.wrongSign {
  font-size: 31px !important;
  color: #f53123;
}

.tableBackDeta thead {
  white-space: nowrap;
}

.reviewAnswerBk {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #2e4a8933;
  z-index: 99999;
  overflow: auto;
}

.reviewAnswerBk {
  background: #056fe934;
  padding: 15px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  animation: fadeInDown 1s;
  overflow: auto;
}

.explanationBox {
  margin: 1% 10%;
}

.headingExpl {
  color: #9966ff;
  font-weight: bold;
  font-size: 19px;
}

.expContent {
  font-size: 17px;
  color: #000;
}

.UserProfilecard {
  background: url(../../assets/loginBg.png);
  height: 92vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserProfileHeader {
  background: linear-gradient(#f8f8f8, #fdfdfd);
  border-radius: 10px;
  width: 800px;
  margin: 10px;
  padding: 15px;
  animation: fadeInDown 1s;
}

.userImgs {
  background-image: linear-gradient(#9966ff 46%, #ffffff 50%);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: -78px auto auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Usericons {
  font-size: 122px !important;
  background: #fff;
  border-radius: 50%;
  color: #a6a6a6;
}

.userName {
  text-align: center;
  font-size: 18px;
  margin: 15px 0;
}

.rowDetailes {
  display: grid;
  grid-template-columns: 10fr 10fr;
  max-width: 500px;
  margin: 20px auto;
}

.tagLineImg {
  text-align: center;
  margin: 6% 1% 0 0;
}

.tagLineImg img {
  max-width: 100%;
}

.showDIV {
  animation: fadeInLeft 0.5s;
}

.dropArrow {
  font-size: 62px !important;
  position: absolute;
  bottom: -20px;
  left: 44%;
  color: #b590ff !important;
}

.addClassForbg {
  padding: 15px 10px;
  background: rgb(21 164 247 / 9%);
  margin: 5px 0;
  border-radius: 8px;
  animation: fadeInLeft 0.5s;
}

.SectionHeading {
  font-size: 18px;
  animation: fadeInLeft 0.5s;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.showSms {
  text-align: center;
  animation: fadeInLeft 0.5s;
  max-width: 320px;
  margin: 20px 0;
}

.sectionWiseContainer {
  text-align: center;
}

.popupBack {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #137af036;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errBox {
  background: #fff;
  width: 500px;
  padding: 3px;
  margin: 10px;
  border-radius: 4px;
  animation: fadeInDown 1s;
}

.crossPopup {
  text-align: right;
  float: right;
  margin: 0 0 0 2px;
  background: #f3f3f3;
  height: 48px !important;
  width: 44px !important;
  border-radius: 4px;
  cursor: pointer;
}

.pdfShowFrame {
  background: #f7f7f7;
  margin: 15px;
  padding: 10px;
  border-radius: 4px;

}

.pdfShowFrame iframe {
  width: 100%;
  min-height: 94vh;
  text-align: center;
}

.buttonSection {
  margin: 10px 15px;
}

.timeLimetButton {
  color: #fff;
  text-transform: uppercase;
  padding: 4px 10px;
  width: 176px;
  text-align: center;
  border-radius: 0 2px 15px 15px;
  margin: -2px 0px 0 83px;
  background: #b590ff;
  font-size: 14px;
}

.sectionDivRadio {
  background: #f2f2f2;
  padding: 0px 13px;
  border-radius: 15px;
  text-align: center;
  margin: 0 10px !important;
  box-shadow: 2px 3px 4px #0d0c0c1c;
}

.showSectionBack {
  text-align: center;
  margin: 20px 0;
  animation: fadeInLeft 0.5s;
}

.SectionWise_row {
  animation: fadeInLeft 0.5s;
  padding: 10px 0;
}

.basePopConfirm {
  background: rgba(0, 0, 0, 0.212);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerWhiteBox {
  width: 500px;
  padding: 15px;
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
}

.iconAreYouSure {
  font-size: 70px !important;
  margin: 20px 0;
  color: #4ba4f7;
  border-radius: 50%;
  border: 1px solid #4ba4f7;
}

.messageTxt {
  font-size: 18px;
  color: #000;
}

.examsBgs {
  background: url(../../assets/loginBg.png);
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerBoxIntre {
  background: #fff;
  width: 100%;
  margin: 3% 8%;
  min-height: 80vh;
  border-radius: 5px;
  animation: fadeInLeft 0.5s;
  position: relative;
}

.headersLine {
  background: linear-gradient(to right, #57c5f0, #8fdaff);
  border-radius: 6px 6px 0 0;
  width: 100%;
  height: 14px;
  display: block;
}

.innerBoxcontent {
  margin: 20px;
}

.Heading {
  font-size: 22px;
  color: #000;
  font-weight: bold;
}

.colImgs img {
  max-width: 100%;
  margin: 20% 0;
}

.rowDivs {
  display: grid;
  grid-template-columns: 5fr 95fr;
  font-size: 21px;
  text-align: justify;
}

.headingDot {
  font-size: 25px;
  color: #084a95;
}

.buttonSectionRow {
  bottom: 10px;
  text-align: right;
  max-width: 100%;
}

.sectionBtndiv {
  background: #f2f2f2;
  padding: 0px 17px 0 5px;
  border-radius: 15px;
  text-align: center;
  margin: 0 10px !important;
  box-shadow: 2px 3px 4px #0d0c0c1c;
}

.pdfPop {
  position: fixed;
  top: 0;
  left: 0;
  background: #006deb;
  width: 100%;
  height: 100%;
  z-index: 99999;
  animation: fadeInDown 1s;
}

.closePDFPop {
  position: absolute;
  top: 2.1%;
  right: 20%;
  z-index: 99999;
  background: #d5d4d4;
  padding: 2px 20px;
  border-radius: 2px;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 1px 1px 2px #888;
}

.closePDFPop:hover {
  background: #c0bebe;
}

.paperBg {
  padding: 15px;
  color: rgb(0, 0, 0);
  background-color: #FBFBFB;
  box-shadow: 0 5px 10px rgba(221, 221, 221, 0.4);
  width: 370px;
  margin: 20px auto;
  cursor: pointer;
  text-align: center;
  border: 2px dotted #a37af4;
  border-radius: 15px;
  animation: fadeInLeft 0.5s;
}

.pdfIcons {
  background: #1a6cca;
  height: 66px;
  width: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  border: 4px solid #fff;
  margin: -38px auto auto;
  box-shadow: 0px 2px 3px #0606064a;
}

.pdficons {
  font-size: 44px !important;
}

.QuickNotesButton {
  text-align: center;
  margin: 20px 0;
}

.QuickNotesButton button {
  width: 250px;
  margin: 24px 0 !important;
  background: #4480c6;
  display: table-cell;
  min-height: 65%;
}

.iaccInput input {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}

.iaccInput {
  display: flex;
  align-items: center;
  font-size: 21px;
}

.rowWithicons {
  margin-top: 20%;
}

.bestImages {
  text-align: right;
  margin-bottom: 1%;
}

.bestImages img {
  width: 170px;
}

.sectionRow {
  text-align: center;
  animation: fadeInLeft 0.5s;
  margin: 20px 0;

}

.partSectionRow {
  text-align: center;
  animation: fadeInLeft 0.5s;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsPart {
  color: #fff;
  text-transform: uppercase;
  padding: 9px 10px;
  width: 176px;
  text-align: center;
  border-radius: 0 2px 15px 15px;
  margin: 10px;
  background: #9465f2;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  border-left: 3px solid #3e103b;
  border-bottom: 3px solid #3e103b;
}

.titleShow {
  padding: 3px 25px;
  float: right;
  border: 1px solid #999;
  border-radius: 50px;
  text-align: center;
  margin: 5px;
  background: #dfdfdf;
}

.StatusText {
  color: #8a2929;
  text-align: right;
  padding: 0 15px;
  cursor: default;
}

.StatusTextMrp {
  color: #8a2929;
  text-align: left;
  padding: 0 15px;
  cursor: default;
  /* position: absolute; */
  /* margin-left: 366px; */
  /* margin-top: 24px; */
  clear: both;
  font-size: 14px;
}

.StatusPerchased {
  color: #868585;
}

.sectionboxX {
  max-width: fit-content;
}

.sectionboxX:hover {
  background: #bdbdbd;
}

.sectionbox {
  margin: 0 auto;
  background: #ececec;
  padding: 1px 15px;
  font-size: 19px;
  border-radius: 20px;
  z-index: 2;
  cursor: pointer;
  max-width: 153px;
  display: inline-block;
  margin: 10px;
}

.rowClass {
  display: flex;
}

.col6 {
  width: 40%;
}

.headingHelpIcon {
  float: right;
  cursor: pointer;
  font-size: 18px;
}

.colorBlack {
  color: #fff !important;
}