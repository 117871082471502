.container{
    background-image: url(../../assets/loginBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginDiv{
    background: #fff;
    margin: 0 20px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    z-index: 999;
}
.loginForm{
    padding: 2% 20%;
}

.loginImg2 img{
    width: 100%;
}

.loginHead{
    text-align: center;
    font-family: 'calibri';
    color: #4D4D4D;
    margin-top: 0;
}
.lableHead{
    font-family: 'Calibri';
    font-size: 20px;
    font-weight: bold;
    color: #4D4D4D;
    margin-top: 10px;
}

.inputRow{
    display: grid;
    grid-template-columns: 1fr 10fr;    
    border-bottom: 1px solid #bbb;
    margin-top: 5px;
    margin-bottom: 10px;
}
.icon{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #042954;
    font-size: 1.8rem;
}
.inputRow input{
    font-family: 'Calibri';
    font-size: 18px;
    width: 100%;
    height: 30px;
    border: none;
}
.inputRow input:focus{
    outline: none;
}
.buttonRow{
  text-align: center;
}

/* add by Ajay */
.errBox{
  background: #fff;
  width: 500px;
  padding: 3px;
  margin: 10px;
  border-radius: 4px;
  animation: fadeInDown 1s;
}
.crossPopup{
  text-align: right;
  float: right;
  margin: 0 0 0 2px;
  background: #f3f3f3;
  height: 48px !important;
  width: 44px !important;
  border-radius: 4px;
  cursor: pointer;
}
.bulkUploadPop{
  background: #00000040;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupBack{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #137af036;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* add by Ajay */

.logButton:hover{
    background: #003399;
}
.logButton{
  font-family: 'calibri';
  background: #042954;
  color: #fff;
  position: relative;
  width: 157px;
  padding: 10px;
  border-radius: 29px;
  font-size: 18px;
  margin: 20px;
}
.logButton:after {
    content: "";
    width: 116px;
    height: 5px;
    background: #0cf;
    background: linear-gradient(0deg,rgba(0,204,255,0),hsla(0,0%,100%,.594));
    position: absolute;
    top: 2px;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 20px;
}
/* animation css */
.context {
    top:50vh;
  }
    .context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
  }
  .area{
    background: #4e54c8;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    height:100%;
  }
  .circles{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }
  .circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }
  .circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  .circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  .circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  .circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  .circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  .circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  .circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  .circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  .circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  .circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
  .erroBox{
    animation: fadeInDown 1s;
    margin-bottom: 0 !important;
  }
  .dropdownItem{
    padding: 4px 10px;
  }
  .bottomMenu{
    position: absolute;
    bottom: 0px;
    min-height: 25px;
    left: 0px;
    right: 0px;
    background-color: rgb(0 0 0 / 41%);
    z-index: 99999;
    padding: 3px;
  }
  .bottomMenu > ul{
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 0px;
  }
  .bottomMenu li{
    margin: 0;    
    display: inline-block; 
    border-right: 1px solid #fff;  
    padding: 5px 20px;
    color: #dbdbdb !important;
    font-size: 15px; 
    font-weight: 300;   
    cursor: pointer;        
  }
  .bottomMenu li:nth-child(1){
    margin: 0;    
    display: inline-block; 
    border-left: 1px solid #fff;             
  }  
  .bottomMenu a{
    margin: 0;     
  }
  .bottomMenu a:hover{    
    color: #ffffff !important;
    /* font-size: 18px !important;      */
  }
  .addMoreSubjectBg{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    z-index: 9999;
}
.generalInstructionBg{
    background: #fff;
    width: 100%;
    right: 0;
    animation: stuDashboard_fadeInDown__1xgpI 1s;
    box-shadow: -6px 22px 21px #04040442;
    margin: 40px;
    max-height: calc(100% - 80px);
    min-height: calc(100% - 80px);
}
.headingBk{
    padding:11px 10px;
    background: #ebebeb;
}
.SectionHeading{
    margin: 2px 0 0 0;
    font-size: 16px;
    font-weight: bold;
}
.SectionHeadingInst{
  margin: 2px 0 0 0;
  font-size: 26px;
  font-weight: bold;
}
.crossbutton{
    float: right;
    font-size: 31px !important;
    color: #7e7e7e;
    cursor: pointer;
}
.SubjectBodyArea{overflow-y: scroll;
  overflow-y: scroll;
min-height: calc(100% - 143px);
position: absolute;
width: calc(100% - 80px);
max-height: calc(100% - 143px);
font-size: 22px;
}
.SubjectBodyContainer{padding: 15px;}
.tableCLS{width: 100%;
  border: 1px solid #535353;
  margin: 0 0 10px 0;}
.tableCLS th{width: 50%;
  background-color: #535353;
  color: rgb(255, 255, 255);    
  padding: 3px 10px;}
.tableCLS td{width: 50%;    
    
  padding: 3px 10px;}
@keyframes animate {
  
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
  
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
  
}
/* animation css */
@media screen and (max-width: 1200px) {
  .bottomMenu{   
    display: none;
  }
}

@media screen and (max-width: 768px) {
    .logButton{
      margin: 20px 5px;
      width: 150px;
      padding: 7px;
    }
    .loginForm{
        padding: 2% 3%;
    }
    .loginHead{
        font-size: 15px;
    }
    .lableHead{
        font-size: 17px;
    }
    .inputRow input{
        font-size: 16px;
    }
    .icon{
        padding: 0 5px;
    }
    .generalInstructionBg{
        background: #fff;
        width: 100%;
        right: 0;
        animation: CodeLogin_stuDashboard_fadeInDown__1xgpI__1u4NT 1s;
        box-shadow: -6px 22px 21px #04040442;
        margin: 10px 30px;
        max-height: calc(100% - 148px);
        min-height: calc(100% - 148px);
    }
    .SubjectBodyArea{overflow-y: scroll;
        overflow-y: scroll;
        min-height: calc(100% - 211px);
        position: absolute;
        width: calc(100% - 60px);
        max-height: calc(100% - 211px);
        font-size: 22px;
    }

    .bottomMenu li:nth-child(1) {      
      display: inline-block;
      border-left: none;
    }

    .bottomMenu{
      position: absolute;
      bottom: 0px;
      min-height: 25px;
      left: 0px;
      right: 0px;
      background-color: rgb(0 0 0 / 41%);
      z-index: 99999;
      padding: 3px;
      display: none;
    }
    .bottomMenu > ul{
      margin: 0 auto;
      text-align: center;
      width: 100%;
      padding: 0px;
    }
    .bottomMenu li{
      border-bottom: 1px solid #686868;
      padding: 0px 7px;
      color: #dbdbdb !important;
      font-size: 12px;
      font-weight: 300;
      cursor: pointer;
      border-right: none;  
      margin-right: 5px;     
    }
}
