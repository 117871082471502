body{
    font-size: 16px;
}
@keyframes fadeInDown {
    0%{
        opacity: 0;
        transform: translateY(-100px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
  }
.container{
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 93vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
}
.invoContainer{
    background: #fff;
    max-width: 1280px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: fadeInDown 1s;

}
.header{
    display: grid;
    grid-template-rows: 60px 1fr;
}
.firstHead{
    display: grid;
    grid-template-columns: 1fr 350px;
}
.logoBg{
    /* background: #9966FF; */
    padding: 10px;
}
.btnSec{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.btnStyle{
    height: 40px;
}
.iconStyle{
    font-size: 20px !important;
    margin-left: 6px;
}
.secondHead{
    display: grid;
    grid-template-columns: 1fr 350px;
    border-bottom: 1px solid #E3E4E6;
    margin-bottom: 10px;
    
}
.addressSec{
    display: flex;
    align-items: flex-end;
    padding: 10px;
}
.invoSec{
    align-items: flex-end;
    padding: 10px;
}
.invoHead{
    font-size: 50px;
    color: #9966FF;
}
.consumerSec{
    display: grid;
    grid-template-columns: 1fr 350px;
    margin-bottom: 10px;
    grid-gap: 10px;
}
.paymentSec{
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: 30px 30px 30px;
    padding: 10px;
}
.payList{
    border: none;
    background: none;
}
.studentSec{
    display: grid;
    grid-template-columns: 150px 1fr;
    
    padding: 10px;
}
/* .inputBox{
    border-bottom: 1px solid gray;
} */
.inputStyle{
    width: 100%;
    border: 0;
}
.lableText{
    margin: 0px 2px 2px;
}
.compSec{
    display: grid;
    grid-template-columns: 150px 1fr 200px;
    padding: 0 10px;
    align-items: center;
    border-bottom: 1px solid #E3E4E6;
}
.sectionB{
    padding: 0 10px;

}
.sectionList{
    border: none;
    background: none;
    width: 200px;
}
.selectList{
    display: flex;
}
.tickList{
    margin-right: 20px;
}
.tickBox{
    margin: 0 10px;
}
.secStyle{
    font-size: 14px;
}
.fixedPrice{
    text-align: center;
    margin-right: 10px;
    font-weight: bold;
}
.subjectList{
    padding: 0 10px;
}
.tableStyle{
    width: 100%;
    border-bottom: 3px solid #9966FF;
}
.tableStyle tr th{
    background:#9966FF;
    color: #fff;
    border: 2px solid #fff;
    padding: 4px;
}
.tableStyle tr td{
    border: 2px solid #fff;
    padding: 4px;
}
.tableStyle tr:nth-child(even){
    background: #EDEDED;

}
.centerAlizn{
    text-align: center;
}
.totalSec{
    width: 100%;
    padding: 0 10px;
    /* background: lightcoral; */
}
.totalSec td{
    padding: 0 10px;
}
.totalAmt td{
    font-weight: bold;
    border-top: 1px solid gray;
    
}
.signSec{
    border-bottom: 1px solid #E3E4E6;
    padding: 10px 60px;
    margin-top: 40px;

}
.thankuMsg{
    font-family: Trajan;
    text-align: center;
    font-size: 30px;
    padding: 10px 0;
    color: #174F8F;

}
.Invoice_container__2ER_9{
    padding: 0 !important;
  }
@media print{
    .btnSec{
        display: none;
    }
    .container{
        background: none;
        padding: 0;
    }
    .invoContainer{
        width: 100%;
    }
    .invoContainer{
        box-shadow: none;
    }
    .secondHead{
        grid-template-columns: 1fr 250px;
        
    }
    .consumerSec{
        grid-template-columns: 1fr 250px;
    }
    
    .SuperAdmin_basePopDiv__3J6tL {
        background: #fff !important;
    }
    .SuperAdmin_basePopDiv__3J6tL {
        background: #ffffff;
      }
      .Invoice_secondHead__3Ou0j {
        display: grid;
        grid-template-columns: 1fr 442px;
   
      }
    
}
