body {
    overflow-x: hidden;
    padding: 0;
}
.topsection {
    background: #ec1e25;
    color: #fff;
    text-align: center;
    padding:12px 0;
}
.width-section {
    max-width:92%;
    margin: 0 auto;
}
.cuetlogo {
    max-width:85%;
    padding:6px 0;
}
.togetherLogo {
    text-align: right;
    margin:4px 0;
}
.shadowB {
    padding:3px 0;
    box-shadow: 1px 4px 9px -5px;
    margin: 0 0 8px 0;
}
.banner-section {
    max-width:80%;
    margin: 15px auto;
    overflow: hidden;
}
.banner-section h1 {
    color: #ed3338;
    letter-spacing: 2px;
    font-size: 2.1vw;
    font-weight: 600;
    margin: 22px 0 35px 0;
    text-transform: uppercase;
    text-align: center;
    animation: 4s slide-right;
}
/***** Slide Right *****/
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
/***** Slide Right *****/
.banner-section p {
    color: #000000;
    font-size:17px;
    font-weight: 100;
    margin: 6px 0;
    text-align: justify;
    letter-spacing:1px;
}
.banner-section img{
    padding:6px 0;
}
.animation{
    overflow: hidden;
    transform: translatey(0px);
    animation: float 3s ease-in-out infinite;
}
@keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
}
/*Login section  */
.login-section {
    padding:35px 8px;
    margin:22px auto;
    box-shadow: 1px 1px 8px #979797;
    text-align: center;
    border: 1px solid #979797;
    border-top:8px solid #f3372d;
    animation: fadeInDown 2s;
    background: #FFF;
    /* position: relative; */
}
@keyframes fadeInDown {
    0%{
        opacity: 0;
        transform: translateY(-500px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
 }
.user {
    border-radius: 100%;
    border: 1px solid #a4a4a4;
    display: inline-block;
    width: 85px;
    height: 85px;
    margin: 3px 0 10px 0;
    padding: 10px 0;
}
.input-container {
    display: flex;
    justify-content: center;
    margin: 8px 0;
    padding: 12px 0 12px 0;
}  
.icon {
    padding:10px;
    background: #fff;
    color: rgb(3, 3, 3);
    min-width:52px;
    text-align: center;
    border: 1px solid #767676;
    border-right: none;
    font-size: 22px;
}  
.input-field {
    width:80%;
    padding: 10px 6px;
    outline: none;
    background:#e6e6e6;
    border: 1px solid #767676;
    font-size: 15px;
}  
.input-field:focus {
    border:1px solid rgb(119, 146, 174);   
}
.login-btn {
    background-image: linear-gradient(to right, #f95036 0%, #f95036 51%, #ec1d24 100%);
    border-color: #929292cc;
    margin: 6px 0 12px 0;
    width:72%;
    padding: 8px 0;
    border-radius: 2px;
    font-size:16px;
    color: #fff;
    outline: none;
    transition: all .3s ease;
}
.login-btn:hover{
    opacity: 0.8;
}
.login-btn:focus {
    border-color: #929292cc;
    box-shadow: none;
    outline: none !important;
}
.register-btn {
    background-image: linear-gradient(to right, #ec1d24 0%, #f95036 51%, #f95036 100%);
    border-color: #929292cc;
    margin: 6px 0 12px 0;
    width:72%;
    padding: 8px 0;
    border-radius: 2px;
    font-size:16px;
    cursor: pointer;
    transition: all .3s ease;
}
.register-btn:hover{
    border-color: #929292cc;
    opacity: 0.8;
}
.register-btn a {
    color: #fff !important;
} 
.botton-banner {
    font-size:24px;
    text-align: center;
    padding:5px 6px;
    background:#504f4f;
    color: #fff;
    transition: all .3s ease;
    letter-spacing: 1px;
    margin:8px 0;
} 
.botton-banner:hover{
    background:#ec1c24; 
    color: #fff;
} 
/* Login section */

/*navbar*/
.navigation {
   margin:3px 0 0 0 !important;
}
.navbar {
    padding: 0 0 !important;
}
.navbar-nav li {
    font-size: 17px;
    margin: 0 12px 0 12px;
}
.navbar-nav a {
    color: #000;
    text-decoration: none;
    padding:8px 12px;
    transition: transform .3s;
    border-radius: 4px;
    letter-spacing: 1px;
  }
.navbar-nav a:hover {
    background: #ed3338;
    color: #fff !important;
}
.nav-item.dropdown a {
    padding: 0 6px;
    transition: transform 0.3s;
    color: #000 !important;
    background: #fff;
    border: none;
}
.border-bootem{
    border-bottom: 1px solid #d3d3d3 !important;
}
/*navigation*/

/* Portal section  */
.cuet-portal {
    background-image: url(../../assets/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 6px 10px;
}
.portalBox {
    text-align: center;
    max-width:78%;
    margin: 0 auto;
}
.portalBox1 {
    padding:15px;
    box-shadow: 0px 8px 6px 0 rgb(0 0 0 / 34%);
    overflow: hidden;
    transition: all 0.2s ease-in-out 0s;
    margin: 12px 6px;
    border: 3px solid #b52026eb;
    background:#fff;
    height: 92%;
}
.portalBox1:hover{ 
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.15); 
}
.portalBox-image{
    padding: 6px 3px;
    margin:6px auto 8px auto;
    width:40%;
}
.portalBox-image:hover{ 
    border-color: #282828; 
}
.portalBox-image img {
    max-width:80%;
    animation: zoom-in-zoom-out 2s ease-out infinite;
    border-radius:12px;
    border:2px dotted #ec3338;
    margin:6px 0;
}
.portalBox p{
    font-size: 16px;
    text-align: center;
    padding: 6px 10px
}
.portalBox1 h4 {
    background: #ec3338;
    color: #fff;
    padding: 12px 2px;
    font-size:19px;
    margin:12px 0;
    border-radius: 4px;
    transition: all .3s ease;
}
.portalBox1 h4:hover {
    background: #464646;
}
.online-portal {
    padding: 22px 0;
    max-width:70%;
    margin: 0 auto;
}
.online-portal img{
    max-width: 100%;
    transform: translatey(0px);
    animation: float 3s ease-in-out infinite;
    padding: 6px 3px;
}
@keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
}
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
}
.largeSpace{
    padding: 35px 0;
}
.online-portal h2{   
  font-size: 2.7vw;
   text-align: left;
   letter-spacing:4px;
}
.largeFont{
    font-size: 2.7vw;
    color:#f4282f;
    font-weight: 600;
    text-align: left;
    letter-spacing:4px;
    text-transform: uppercase;
}
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
}

/* Portal section  */

/* Icon Box */
.iconBox {
    max-width:80%;
    text-align: center;
    margin: 24px auto 24px auto;
    padding: 30px 0px;
}
.iconSection {
    background: #eb3338;
    padding:15px 6px;
    border-radius: 12px;
    position: relative;
    margin: 30px 0;
    height: 95%;    
}
.iconSection:before, .iconSection:after {
    content: '';
    height: 20px;
    width: 20px;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    left: 50%;
    bottom: -10px;
    background: #eb3338;
}
.iconOne {
    background: #eb3338;
    border-radius: 50%;
    border: 6px solid #fff;
    padding:12px;
    position: absolute;
    top: -22%;
    left: 32%;
    transition: all .3s ease;
    animation: animName 5s linear infinite;
}
.icon2 {
    background:#333;
} 
@keyframes animName {
    0%{
       transform: rotate(0deg);
      }
   100%{
       transform: rotate(360deg);
      }
   }
.iconOne:hover {
    background: #212121;
}
.iconSection h3 {
    margin: 15% 0 4% 0;
    color: #fff;
    font-weight: 500;
    font-size: 25px;
}
.iconSection p {
    color:#fff;
    font-weight:normal;
    font-size:17px;    
}
/* Icon Box */

/* Subject Box  */
.subjects-box {
    border: 2px dotted rgb(27, 19, 19);
    /* border-radius:12px 0;  */
    height:99%;
    position: relative;
    padding:15px 0;
    margin:12px 0 12px 0;
}
.section-bar {
    position: absolute;
    top: -24px;
    left: 10%;
    font-size: 16px;
    background: #ec3338;
    color: #fff;
    padding: 9px 1px;
    width: 80%;
}
.subject-name {
    display: flex;
    justify-content: center;
    align-items: center;
    height:99%;
    font-size:24px;
    font-weight: 500;
}
.subList {
    margin: 12px 0;
    align-items: center;    
}
.subList li {
    text-align: left;
    font-size: 17px;
    padding: 3px 0px;
    word-wrap: break-word;
}
/* Subject Box  */

/* Footer */
.footer-section {
    /* background: #ec1c24; */
    background-image: linear-gradient(90deg, #ec1c24, #fc5e3b);
    color: #fff;
    padding:12px 0;
    margin:8px 0 0 0;
}
.footer-section h2 {
    text-align: center;
    padding:18px 0;
    font-size:28px;
    letter-spacing: 1px;
    text-transform:uppercase;
}
.footer-section h3 {
    padding: 6px 0 3px 0;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 3px solid #fff;
    margin: 0 0 22px 0;
}
.footer-section p {
    letter-spacing: 1px;
    text-align: justify;
    font-size:15px;
    font-weight: normal;
}
.socialHedi {   
    font-size:20px;
    margin: 0 0 12px 0;
}
.social-links img {
    margin: 3px 4px 3px 0;
}
.subscribe {
    text-align: right;
    padding: 0 3px 6px 3px;
}
.subscribe input {
    padding: 10px 6px;
    width:100%;
    border: none;
    outline: none;
    font-size: 17px;
}
.subscribe-btn {
    width: 100%;
    outline: none;
    font-size: 17px;
    padding: 9px 6px;
    border: 1px solid #eb3338;
    transition: all .5s ease;
    background: #eb3338;
    color: #fff;
    box-shadow: 1px 2px 9px #5e3233;
}
.subscribe-btn:hover {
    color: #fff;
    border:1px solid #ccc;
}
.subscribe p{
    text-align: left;
    margin: 8px 0;
  }
.address h4 {
    font-size: 22px;
    margin:12px 0;
}
.address {
    font-size:16px;   
    padding:6px 0;
}
.favi {
    font-size:22px;
    padding: 2px 12px;
    align-items: center;
}
.termsHedi {
    font-size: 20px;
    margin: 0 6px 8px 6px;
    border-bottom: 1px solid #cccc;
}
.list-section {
    list-style: none;
    padding: 0 6px;
}
.list-section li {
    border-bottom: 1px solid #cccc;
    padding:4px 3px;
    font-size: 16px;
    transition: all 1s;
}
.list-section li:hover {
    padding-left:15px;
    border-bottom: 1px solid #fff;
}
.footer-section h4 {
    font-size: 21px;
    margin:10px 0 10px 0;
}
.list-section a {
    color: #fff !important;
}
.payment-section {
    margin:10px 0 10px 0;
    font-size: 21px;
}

/* Footer */
/* --------isloader------  */
.isLoader {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    border: 3px solid;
    /*border-color:#de3500 #0000 #fff #0000;*/
	border-color:#de3500 #0000 #333 #0000;
    border-radius: 50%;
    box-sizing: border-box;
    animation: 1s rotate linear infinite;
 }
 .isLoader:before , .isLoader:after{
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    border: 10px solid transparent;
    border-bottom-color:#fff;
    transform: translate(-10px, 19px) rotate(-35deg);
  }
 .isLoader:after {
    border-color: #de3500 #0000 #0000 #0000 ;
    transform: translate(32px, 3px) rotate(-35deg);
  }
   @keyframes rotate {
    100%{    transform: rotate(360deg)}
  }
/*  ------- isLoader----  */