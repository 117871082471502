/* about us */
.about-section {
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
    padding:16px 0;
}
.abo1{
    max-width: 100%;
}
.about-section h1{
    border-bottom: 1px solid #979595;
    font-size:27px;
    font-weight: 500;
    margin: 4px 0 15px 0;
    padding: 5px 0;
    position: relative;
}
.about-section h1:before {
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 3px;
    left: 48%;
    position: absolute;
    width:40px;
    background: #ec2c32;
}
.about-banner img {
    margin:0 0 6px 0;
}
.about-section p {
    text-align: justify;
    font-size:17px;
    margin:8px 0;
    color: #000;
    /* letter-spacing: 1px; */
}
.fontNew{
    font-family: 'Marck Script';
    font-size:38px;
    margin:32px 0;
    font-weight: bolder;
    letter-spacing: 2px;
}
/* about us */
/* contact us */
.contactSection {
    background: #f7f7f7;
    padding: 15px 0;
    text-align: center;
    margin:10px 0;
}
.contactSection h1 {
    border-bottom:1px solid #afafaf;
    /* display: inline-block; */
    font-size: 24px;
    font-weight: 500;
    margin: 10px 0;
    padding:6px 0;
    position: relative;
}
.contactSection h1:before {
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 3px;
    left: 48%;
    position: absolute;
    width:40px;
    background: #ec2c32;
}
.contact-from{
  margin: 12px 0px;
  padding: 12px 0;
}
.form-group{
  margin:3px;
  /* border:1px solid #333; */
}
.contact-from label {
    padding: 4px 0;
}
.form-control{
  border: 1px solid #c4c4c4;
}
.form-control:focus{
  border-color: #ed3338;
  box-shadow:none;
}
.contact-btn {
    background: #ff5156;
    border: 1px solid #e95c59;
    color: #fff;
    padding: 8px 35px;
    font-size: 17px;
    margin: 18px 0 10px 0;
    box-shadow: 0px 1px 2px #b4b4b4e0;
    transition: all .3s ease;
    border-radius: 4px 0;
  }
.contact-btn:hover {
   border:1px solid #525252de;
   background: none;
   color:#000
}
address{
  text-align: left;
  padding: 12px 0;
  font-size: 16px;
}
address h5{
  margin:6px 0;
  font-weight:500;
  padding: 2px 0 4px 0;
  border-bottom:2px dotted #ff5156;
}
.rightImg{
    text-align: right;
}
address p{
  letter-spacing: 1px;
  margin: 4px 0 0 6px;
}
/* contact us */
/* Privacy Policy   */
.privacy-main{
  background: #f7f7f7;
  padding: 15px 0 0 0;
  text-align: center;
  margin: 10px 0 0 0;
}
.privacy-main h1 {
  border-bottom: 2px dotted #a2a2a2;
  font-size: 22px;
  font-weight:600;
  margin:12px 0;
  padding:4px 6px;
  position: relative;
  text-align: left;
  background: #fff;
}
.privacy-main h1:before {
  bottom: -2px;
  content: "";
  display: inline-block;
  height: 3px;
  left:6%;
  position: absolute;
  width:80px;
  background: #ec2c32;
}
.privacy-main p{
  font-size: 17px;
  text-align: justify;
  padding: 0 6px;
}
.privacy-section{
  background-image: url(../../assets/bg.png);
  background-size: 100%;
  background-attachment: fixed;
  margin:12px 0;
}
.privacy-section p{
  text-align: justify;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 17px;
  border: 3px double #f9533757;
  background: #fff;
  transition: all .3s ease;
  margin:18px 0;
}
.privacy-section p:hover{
  border:3px double #957e16e7;
}
/* Privacy Policy   */
/* Product */
.Product-section {
  /* border: 1px solid #ccc; */
  margin: 14px 2px;
  background: #eae9e9de;
  /* box-shadow: 1px 4px 10px 0 rgb(0 0 0 / 21%); */
}
.Product-section h2 {
  /* background: #535353; */
  background:#ed3338e8;
  font-size: 21px;
  padding: 8px 12px; 
  color: #fff;
  text-align: left;
  letter-spacing: 1px;
}
.productSub{
  padding:3px 12px 12px 12px;
}
.productSub h4{
  font-size:22px;
  margin:2px 0 8px 0;
  font-weight:500;
  text-align: left;
  border-bottom:2px dotted #aaaaaa;
  padding: 6px 0;
}
.product-service h3 {
  font-size: 18px;
  background: #ff3d42;
  padding: 13px 7px;
  color: #fff;
  margin: 6px 0;
  text-align: center;
  letter-spacing: 1px;
  line-height: 26px;
  box-shadow: 1px 4px 5px #b6b6b6;
}
.productSub p{
  font-size:18px;
  margin:3px 0;
  text-align: left;  
}
.product-service li{
  color:#000;
  font-size:19px;  
  margin: 8px 0;
  border-bottom: 1px solid #ababab;
  padding: 3px 2px;
}
.termli::marker {
  color: #ed3338;
  font-size: 25px;
  font-weight: bold;
}
.product-service {
  padding: 12px 4px;
}
.product-service h2 {
  font-size:20px;
  background: #fc464b;
  padding:8px 12px;
  border-radius:1px;
  color: #fff;
  margin: 6px 0;
}
.product-service p {
  margin:15px 3px;
  background:#f1ecec;
  padding: 6px 12px;
  border-radius: 2px;
  font-size:18px;
}
/* Product */
/* blog */
.faqhedi{
  font-size: 22px;
  text-decoration: underline;
}
.postedOn h6{
  color: #848383;
  font-size: 14px;
}
.postedOn p{
  color: rgb(80, 80, 80);
  font-size: 16px;
}
.search-box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin:2px 0 12px 0;
}
.search-box input {
  border-radius: 25px 0 0 25px;
  width:92%;
  padding:9px 0 9px 12px;
  border: none;
  background: #ecedf1;
  box-shadow: 1px 2px 3px #b3b1b1;
  outline: none;
}
.search-box button {
  padding:10px 18px;
  border-radius: 0 25px 25px 0;
  border-left: none;
  background: #ec2b30;
  color: #fff;
  border: none;
  box-shadow: 1px 2px 3px #b3b1b1;
}
.recentPost h2{
  margin: 12px 0;
  font-size: 22px;
  border-bottom: 2px solid #ec3338;
  padding:4px 0 3px 0;
}
.recentpara ul li{
  position: relative;
  list-style: none;
  font-size:15px;
  margin:6px 0;
  text-align: left;
}
.recentpara li::before{
  content: "\f105";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: 600;
  left:-26px;
  position: absolute;
  top: 5px; 
}
.post-thumb{
  margin:12px 0;
}
.post-thumb img{
  margin: 6px 0;
}
.post-thumb h5{
  font-size:17px;
  text-decoration: underline;
  padding:4px 0;
}
.post-thumb h6{
  color: #848383;
  font-size: 14px;
  margin:2px 0;
}
.post-thumb p{
  color: rgb(80, 80, 80);
  font-size: 15px;
  padding:3px 0;
}
.recentpara select {
  padding: 6px 12px;
  width: 90%;
  text-align:left;
  margin:5px 22px;
  background: #dedede;
  border:1px solid #9d9d9d;
  border-radius:4px;
}
.recentpara option {
  background: #ffffff;
}
#border-pagination{
  text-align: center;
}
#border-pagination li{
  display: inline-block;
}
#border-pagination li a {
  display: block;
  text-decoration: none;
  color: #000;
  padding:8px 16px;
  border: 1px solid #ddd;
  float: left;
}
#border-pagination li a {
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s
}
#border-pagination li a.active {
  background: #222222;
  color:#fff !important;
}
#border-pagination li a:hover:not(.active) {
  background: #ddd;
}
/* blog */
/* faq Accordion */
.faqs{
  padding: 6px 2px;
}
.accordion-button {
  color: #000000 !important;
  background: #fff !important;
  padding:11px 12px !important;
  font-size: 18px !important;
  font-weight: bold;
}
.accordion-button:focus{
  box-shadow:none!important;
}
.accordion-body a {
  text-decoration: none !important;
}
.accordion-button::after {
  width: 14px !important;
  height: 14px !important;
  background-size: cover !important;
}
.accordion-body {
  font-size:18px;
  padding:7px 10px !important;
  border-top: 1px solid #848484 !important;
  display: flex;
}
.accordion-button.collapsed::after {
  color:#fff !important;
}
.accordion-item {
  background: #fff;
  border: none !important;
}
.menuButton.accordion-item a {
  text-decoration: none;
} 
.accordion-item:not(:first-of-type) {
  margin: 0px 0;
}
.accordion-button:not(.collapsed) {
  box-shadow:none !important;
}
.bor {
  border: 1px solid #848484;
  margin:10px 0;
} 
.bor1 {
  margin: 0 8px 0 0;
}
.accordion-header ol{
  margin: 0;
  padding: 0 18px;
}
/* faq Accordion */
