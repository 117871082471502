.container{
    background-image: url(../../assets/loginBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border: none;
}
.container1{
  background-image: url(../../assets/loginBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* padding: 0 10px; */
  border: none;
  padding: 0 0 12px 0;
}
.okButton{
  position: sticky;
  bottom: 5px;
  z-index: 999;
  margin-left: 20%;
}
.secTwoRateText{
text-align: left;
padding-left: 5px;
}

.marginSet{
  justify-content: left;
}
.SubjectList{
  height: auto;
overflow: auto;
}

/*  */

.errBox{
  background: #fff;
  width: 500px;
  padding: 3px;
  margin: 10px;
  border-radius: 4px;
  animation: fadeInDown 1s;
}
.crossPopup{
  text-align: right;
  float: right;
  margin: 0 0 0 2px;
  background: #f3f3f3;
  height: 48px !important;
  width: 44px !important;
  border-radius: 4px;
  cursor: pointer;
}
.bulkUploadPop{
  background: #00000040;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupBack{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #137af036;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*  */
.mainDiv{
    width: 976px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding: 0;
}
.regImgBox{
    background-image: url(../../assets/regImg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.regformBox{
    z-index: 2;
    margin: 2% 8%;
}
.head{
    text-align: center;
    font-family: 'calibri';
    color: #4D4D4D;
    margin-top: 0;
}
.head h1{
    margin: 0;
}
.lableHead{
    font-family: 'Calibri';
    font-size: 17px;
    font-weight: bold;
    color: #4D4D4D;

}
.inputBox{
    margin-top: 5px;
    margin-bottom: 20px;
}
.inputBox input:focus{
    outline: none;
    border-bottom: 1px solid #eb1111;
}
.inputBox input{
    width: 98%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #807e7e;

}
.inputRow{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 40px;

}
.selectcombobox:focus{
    outline: none;
    border-bottom: 1px solid #eb1111;
}
.sectionBox:focus{
    outline: none;
    border-bottom: 1px solid #eb1111;
}
.selectcombobox{
    width: 100%;
    background: #fff;
    border: none;
    height: 24px;
    margin-top: 5px;
    border-bottom: 1px solid #807e7e;
}
.sectionBox{
    width: 100%;
    background: #fff;
    border: none;
    height: 28px;
    border-bottom: 1px solid #4D4D4D;

}
.subjRow{
    display: grid;
    grid-template-columns: 2fr 10fr;
}
.sectionBox{
    height: 37px;
}
.rsSymbol{
    font-size: 1.rem;
    align-items: center;
    padding-top: 10px;
}
.btnRow{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin-top: 10px;
}
.logButton:hover{
    background: #7542da;
}
.logButton{
    border-radius: 9px;
    padding: 10px;
    width: 75%;
    color: white;
    font-size: 15px;
    font-weight: bold;
    font-family: 'calibri';
    border: none;
    background: #9966FF;
    cursor: pointer;
}
.logButtonPaytm:hover{
  background: #d3d2d2;
}
.logButtonPaytm{
  border-radius: 9px;
  padding: 10px;
  width: 75%;
  color: white;
  font-size: 15px;
  font-weight: bold;
  font-family: 'calibri';
  border: none;
  background: #efefef;
  cursor: pointer;
  border:1px solid #052E70;
  margin-top:50px;  
}



.btnSetting{
    display: flex;
    justify-content: center;
}
.head h1{
    font-size: 26px;
    font-weight: bold;

}
.rowSection{
    display: block;
    grid-template-columns: 10fr 10fr;
    margin: 11px 0 0 0;
}
.containerSe {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .containerSe input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #2196f3;
  }
  
  /* On mouse-over, add a grey background color */
  .containerSe:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .containerSe input:checked ~ .checkmark {
    background-color: #2196F3;
    border-radius: 3px;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .containerSe input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .containerSe .checkmark:after {
    left: 9px;
    top: 2px;
    width: 7px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(
45deg);
  }
  .SubjectList label{
    border-bottom: 1px solid #e7e7e7;
    padding: 1px 0 10px 50px;
   }
.headerSltsubject{
    background: #e5e5e5;
    padding: 5px 10px !important;
}
/* animation css */
.context {
    top:50vh;
  }
    .context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
  }
  .area{
    background: #4e54c8;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    height:100%;
  }
  .circles{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }
  .circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }
  .circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  .circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  .circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  .circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  .circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  .circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  .circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  .circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  .circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  .circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
  
  @keyframes animate {
  
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
  
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
  
  }
/* animation css */

/* loader style */
.isLoader{
    min-height: 648px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* loader style */


/* Payment Success css start */
@keyframes fadeInDown {
  0%{
      opacity: 0;
      transform: translateY(-500px);
  }
  100%{
      opacity: 1;
      transform: translateY(0);
  }
}
.paymentSuccssBg{
  background: #fff;
  padding: 30px 15px;
  width: 1024px;
  margin: 10px;
  text-align: center;
  z-index: 99999;
  animation: fadeInDown 1s;
  border-bottom: 3px solid #ba68c8;
}
.checkMark{
  font-size: 70px !important;
  color: #019214;
}
.payText{
  font-size: 30px;
  color: #838282;
  margin: 15px 0;
}
.thanksMsg{
  color: #838282;
  margin: 15px 0;
  font-size: 20px;

}
.pymentImg{
  padding: 10px
}
.pymentImg img{
  max-width: 100%;
}


/* signupSuccess - start */
.signupSuccess{
    min-height: 648px; display: flex; align-items: center; justify-content: center; flex-direction: column;
    text-align: center;
}
.signupSuccessTable{
  width:80%;  
  margin-bottom: 15px;
}
.signupSuccessTableHeading{
  width:50%;
  border: 1px solid #cecece;
  margin-bottom: 15px;
  padding: 5px;
  text-align: left;
  font-weight: bold;
  background-color: #4D4D4D;
  color: #fff;
}
.signupSuccessTable td{
  width:50%;
  border: 1px solid #4D4D4D;
  margin-bottom: 15px;
  padding: 5px;
  text-align: left;
}
.logButtonSignup{
  border-radius: 9px;
  padding: 10px;
  width: 75%;
  color: white;
  font-size: 15px;
  font-weight: bold;
  font-family: 'calibri';
  border: none;
  background: #9966FF;
  cursor: pointer;
}
.logButtonSignup:hover{
  color: rgb(214, 214, 214);
  background: #7542da;
}
/* signupSuccess - end */
.StatusTextMrp{    color: #2a2a2a;
  float: right;
  font-size: 13px;}


@media screen and (max-width: 768px) {
    .mainDiv{
        width: 450px;
        grid-template-columns: 1fr;
    }
    .regImgBox{
        display: none;
    }
    .inputRow{
        grid-gap: 20px;
    
    }
   
  }
@media screen and (max-width: 900px) {
    .inputBox{
        margin-top: 3px;
        margin-bottom: 15px;
    }
    .inputBox input{
        height: 28px;
    }
    .logButton{
        width: 90%;
        font-size: 13px;
    }
    .head h1{
        font-size: 28px;
    }
    .inputRow{
        grid-gap: 30px;
    
    }
    
  }
@media screen and (max-width: 800px) {
    .inputBox{
        margin-top: 2px;
        margin-bottom: 10px;
    }
    .inputBox input{
        height: 25px;
    }
    .logButton{
        width: 85%;
        font-size: 13px;
    }
    .head h1{
        font-size: 28px;
    }
    .inputRow{
        grid-gap: 30px;
    
    }
    .selectcombobox{
        height: 23px;
    }
  }
