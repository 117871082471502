.mainDiv{
    background: #F0F1F3;

}
.selectType{
    background: #fff;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    animation: fadeInDown 1s;
    width: calc(100% + 12px);
}
.slcDiv select{
    word-wrap: normal;
    background: #fff;
    width: 100%;
    padding: 8px 8px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    margin: 7px 1px;

}

.queTopBar{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    background: #E1E3E6;
    border-bottom: 1px solid #C4C5C6;
    }
.queId{
    text-align: left;
}
.idBox{
    width: 80px;
    background: #6c757d;
    border-radius: 4px;
    color: #fff;
    padding: 5px;
    text-align: center;
}
.quiTypeHead{
    text-align: center;
}
.editDeleteBtn{
    text-align: end;
}

.rowGrid{
    display: grid;
    grid-template-columns: 4fr 96fr;
    padding: 4% 4%;
    font-size: 19px;
    background: #fff;
}
.colFixed{
    width: 106px;
}
.QuestionNo{
    color: #9966ff;
    font-weight: bold;
}
.questionDiv label{
    margin: 20px 0;
}
.SpaceVar{
    height: 10px;
}
.optionsRow{
    display: grid;
    grid-template-columns: 5fr 95fr;
}
.optionsRow input[type="radio"]{
    background-color: #fff;
    font: inherit;
    color: currentColor;
    height: 15px;
    width: 21px;
    border: 0.15em solid #a37af4;
    border-radius: 50%;
    cursor: pointer;
}
.divOne{
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  width: 57px;
}

.quesExplanation{
    background: #fff;
    padding: 2% 4%;
}
.quesPassage{
    background: #fff;
    padding: 2% 4% 1% 4%;
    font-size: 19px;
    font-weight: bold;
}

.queEditInfo{
    background: #F0F1F3;
    padding: 10px 20px 50px 20px;
}
.queInfoHead{
    padding: 5px  10px;
    background: #a37af4;
    border: 1px solid gray;
    color: #fff;
}
.queTable{
    width: 100%;
    background: #fff;
}

.queTable tr th{
    padding: 5px;
    border: 1px solid gray;
}
.queTable tr td{
    padding: 5px;
    border: 1px solid gray;
}
.pagination{
    padding: 10px;
    background: #6c757d;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -3px 5px #c5c5c5;
}
.pagination nav ul{
    justify-content: center;
    align-items: center;
    margin: 0;
}
.pagination nav ul li button{
    color: #fff;
    border-color: #fff;
}
.questionContainer{
    /* height: calc(100vh - 66px - 79px - 78px);
    overflow-y: hidden;
    overflow-y: auto; */
    padding-bottom: 50px;
    animation: fadeInDown 1s;

}

.fotter{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}


/* loaderClass - start */
.loaderClass{display: flex;align-content: center;justify-content: center}
/* loaderClass - end */


@keyframes fadeInDown {
    0%{
        opacity: 0;
        transform: translateY(-100px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
