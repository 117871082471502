.maincontainer{
    background-color: #fff;
    /* min-height: calc(100vh - 66px) */
}
.addQueBar{
    padding: 20px;
    border-bottom: 1px solid #C4C5C6;
    background-color: #F0F1F3;
    /* position: fixed;
    left: 0;
    right: 0; */
}
.editQueSec{
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    animation: fadeInDown 1s;
}
.queCode{
    margin-right: 20px;
}
.queCode label{
    margin-right: 20px;
}
.inputText{
    width: 150px;
}
.goBtn{
    width: 80px;
}
#NoofQ{
    cursor: not-allowed;
  }


/**/
.viewQuestionDiv{
    animation: fadeInLeft 1s;

}
.queBox{
    display: grid;
    grid-template-columns: 1fr 15fr;
    grid-gap: 20px;
    padding: 30px;
    
}
.queNo{
    white-space: nowrap;
    font-weight: 600;
    color: #0d6efd;
}
.optionRow{
    padding: 10px 0;
}
.optionRow{
    display: flex;
}
.cellOne{
    white-space: nowrap;
    font-size: 18px;
    font-weight: bold;
    width: 100px;   
    padding: 0 20px 0 0;
}
.cellOne input[type="radio"]{
    background-color: #fff;
    font: inherit;
    color: currentColor;
    height: 15px;
    width: 21px;
    border: 0.15em solid #0d6efd;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 6px;
}
.editAndDelBtn{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid gray;
}


.slcDivSelect{
    word-wrap: normal;
    background: #fff;
    width: 100%;
    padding: 8px 8px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    margin: 7px 1px;

}



/* loaderClass - start */
.loaderClass{display: flex;align-content: center;justify-content: center}
/* loaderClass - end */







.buttonDiv{
    display: flex;
    /* background: crimson; */
    animation: fadeInLeft 1s;
    
}

.addQueFormContainer{
    padding: 10px 20px;
    background: #fff;

}
.sectionDiv{
    padding: 20px 20px;
    /* width: 100.9%; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    animation: fadeInDown 1s;

}
.RowLabel{
    font-size: 16px;
    padding: 6px 0px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 30px 0 0;
  }
  .selectTopic{
    padding: 20px 20px;
    animation: fadeInDown 1s;

  }
  .selectSubject input, select:focus{
    outline: none;
  }
  .selectSubject input{
    width: 100%;
    padding: 10px 10px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    margin: 7px 1px;
  }
  .selectSubject input.formControlSm{
    padding: 3px 5px !important;
  }
  .selectSubject select{
    width: 100%;
    padding: 5px 5px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    margin: 7px 1px;
  }
  .selectSubject label{
    font-size: 16px;
  }
  .nextBtn{
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0;
  }
  .buttonNext{
      width: 100px;
      border-radius: 5px;
  }




.addQueForm{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3;
    animation: fadeInDown 1s;
}
.passageTypeBtn{
    padding: 10px 0;
    grid-column-start: 1;
    grid-column-end: 3;
    animation: fadeInLeft 1s;
}
.queTextBox{
    grid-column-start: 1;
    grid-column-end: 3;
    background: #F0F1F3;
    border: 1px solid #BDBDBD;
    margin-top: 20px;
}
.optionContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2;
    grid-gap: 10px;
    margin-top: 20px;
}
.optionTextBox{
    background: #F0F1F3;
    border: 1px solid #BDBDBD;
    /* margin-top: 10px; */
}
.ckEditorBox{
    padding: 10px;
}
.queTextBox{
    grid-column-start: 1;
    grid-column-end: 3;
}

.lableText{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin: 0;
}
.lableText p{
    margin: 0;
}
.ck.ck-editor__main > .ck-editor__editable {
    border-color: var(--ck-color-base-border);
    height: 200px !important;
}
.selectOpt{
    display: none;
}
.explnBox{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.CorrectAns{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0;
}
.optBtn{
    word-wrap: normal;
    background: #fff;
    border: none;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #b5b1b1;
    min-width: 150px;
}
.submitBtn{
    margin: 50px 0 30px 0;
}
.saveQue{
    background: #a37af4;
    margin-top: 10px;
}
.editBtn{
    word-wrap: normal;
    background: #0d6efd;
    border: none;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #0d6efd;
    color: #fff;
    min-width: 140px;
}
.editBtn option{
    background: #fff;
    color: black;
}

/*Instruction form*/
.instForm{
    padding: 30px;
    background: #F0F1F3;
    margin: 20px 0 20px 0;
    border-radius: 10px;
    animation: fadeInLeft 1s;
    height: calc(100vh - 125px);
    overflow-y: auto;
}
.addIns{
    animation: fadeInDown 1s;
}
.selectTypeIns{
    margin-bottom: 20px;
}
.selectTypeIns form select{
    padding: 10px;
}
.addInstBtn{
    text-align: end;
    padding: 10px 0;
}
.selectTypeIns form label{
    margin-left: 4px;
}
.insInputBox{
    width: 100%;
}
.textAreaBox textarea{
    border: none;
    border-radius: 4px;
    height: 100px;
    min-height: 100px;
    margin-top: 5px;
    border: 1px solid #e9e9e9;
}
.addMoreBtn{
    padding: 10px 0;
    text-align: end;
}
.instructionList{
    width: 100%;
    margin-top: 30px;
}
.instructionList tr th{
    padding: 8px;
    background: #0d6efd;
    color: #fff;
}
.eachInstruction:hover {
    background: #ddd;
}
.instructionList tr{
    background: #fff;
    padding: 8px;
    border-bottom: 1px solid #C4C5C6;
}
.instructionList tr td{
    padding: 8px;
}
.iconStyle{
    font-size: 15px !important;

}




.addIns label{
    margin-left: 4px;
}
.savBtn{
    text-align: center;
    animation: fadeInDown 1s;
    margin: 20px 0;
}
.popupSavBtn{
    margin: 0;
  }


  .passageS{
      overflow-x: auto;
      overflow-y: auto;
  }
  .passageTable{
    width: 100%;
    margin-top: 20px;
   }
  .passageTable tr{
    border-bottom: 1px solid #C4C5C6;
    padding: 10px;
  }
  .passageTable tr td{
    padding: 8px 10px;
  }

  .passageTable tr th{
    padding: 8px;
    background: #174f8f;
    color: #fff;
  }
  .numQue{
      width: 200px;
      text-align: center;
  }

  
     .passageId{
       width: 100px;
   }
   .srNo{
       width: 100px;
   }
   .addQBtn{
       width: 150px;
       text-align: center;
   }


/*Instruction form*/


/*popup*/
.outGray{
    background:  rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10005;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 10%;
}
.mainContainer{
    min-width: 80%;
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    animation: fadeInDown 1s;
    text-align: center;
}
.mainHead h1{
    color: #2D72FF;
}
.popupSavBtn{
    margin: 0;
}
.editInstText{
    width: 100%;
    margin-bottom: 10px;
}
/*popup*/

.opupBox{
    background:  rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 4% 4%;
}
.addqueForm{
    background:#fff;
    height: 85vh;
    overflow: auto;
    padding: 4px 10px;
    border-radius: 10px;
}
.cancleBtn{
    color: #fff;
    font-size: 40px !important;
    position: absolute;
    top: 10px;
    right: 10px;
}
.saveQueBtn{
    padding: 15px;
    text-align: center;
}

.modelMsg.modal.show{z-index: 99999 !important;}

@keyframes fadeInDown {
    0%{
        opacity: 0;
        transform: translateY(-100px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    0%{
        opacity: 0;
        transform: translateX(-100px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}
.backbutton{
    text-align: right;
}
.backLinkDash{
    background: #0d6efd;
    color: #fff;
    display: inline-block;
    padding: 6px 15px;
    border-radius: 3px;
    cursor: pointer;
    animation: fadeInDown 1s;
}
.colbgs{
    background: #DFDFDF;
    padding: 13px;
    border-radius: 5px;
}
.boxes{
    background: #fff;
}

.scrollingDiv{
    padding: 10px;
    height: 65vh;
    overflow: auto;
}
#questionData{
    display: none !important;
}
.showVal{
    background: rgb(244, 244, 244);
    margin: 15px 0px;
}
.labelWithInput{
    width: 100% !important;
    border-bottom: 1px solid #e7e7e7;
    padding: 1px 10px 10px 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 8px 10px;
    cursor: pointer;

}
.labelWithInput input{
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #2196f3;
    margin: 0 15px 0 0;
}
.labelRow{
    display: flex;
}
.noOfQueInput{
    width: 30%;
    height: 40px;
    margin: 6px 15px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
}
footer{
    display: none !important;
}