@media (max-width: 1399.98px) {
   .portalBox-image {
      width:48%;
   }
 }
 
@media (max-width: 1199.98px) { 
.width-section {
   max-width: 95%;
   }
.navbar-nav li {
   font-size: 15px;
   margin: 0 4px 0 4px;
   }
.navbar-nav a {
   padding: 8px 10px;
}
.banner-section {
   max-width: 92%;
   margin: 10px auto;
}
.banner-section h1 {
   font-size: 2.9vw;
 }
 .portalBox {
   max-width: 92%;
 }
 .portalBox-image {
   width:48%;
}
 .online-portal {
   max-width: 85%;
 }
 .online-portal h2 {
   font-size: 3vw;
 }
 .largeFont {
   font-size: 3vw;
 }
 .iconBox {
   max-width: 92%;
   margin: 12px auto 12px auto;
   padding: 18px 0px;
 }
}

@media (max-width: 991.98px) { 
   .width-section {
      max-width: 98%;
    }
  .cuetlogo {
        padding: 2px 0;
    }
  .togetherLogo {
     text-align: center;
     margin: 8px 0;   
    }
  .navigation {
     display: block;
     margin: 6px 0 0 0 !important;
   }
  .navbar-toggler {
     padding: 0px 4px !important;
     font-size:20px!important;
   }
  .navbar-light .navbar-toggler {
     margin:6px 0 6px 0;
  }
  .navBarCss_ActiveButton__1nJG- {
     background: none;
     color: #3e3e3e !important;
   }
  .navbar-nav {
     text-align: center;
     border: 1px solid #aeaeae;
  }
  .navbar-nav a {
     padding: 5px 13px;
     transition: transform .3s;
     border-radius: 0px;
     letter-spacing: 4px;
  }
  .navbar-nav > li {
     background: #fff;
     color: #fffdfd;
     border-bottom: 1px solid #aeaeae;
     padding: 4px 12px;
  }
   .navbar-nav li { 
     font-size: 16px;
     margin: 0 0px 0 0px;
  }
  .banner-section img {
     max-width:62%;
  }
  .banner-section {
     max-width:98%;
  }
  .banner-section h1 {
   letter-spacing: 2px;
   font-size: 30px;
   margin: 8px 0 20px 0;
 }
  .banner-section p {
     font-size: 16px;
     text-align: justify;
     letter-spacing: 0px;
  }
  .login-section {
     padding: 12px 8px;
     margin:12px auto;
     animation: none;
  }
  .login-btn {
     margin: 6px 0 8px 0;
     width: 80%;
     padding:8px 0;
     font-size:15px;
  }
  .register-btn {
     margin: 6px 0 8px 0;
     width: 80%;
     padding:8px 0;
     font-size:15px;
  }
  .botton-banner {
     font-size:20px;
     padding: 7px 6px;
  }
  .cuet-portal {
     padding: 8px 4px 8px;
  }
.portalBox {
   max-width: 95%;
 }
  .largeSpace {
     padding: 6px 0;
  }
  .online-portal {
     padding:12px 0;
     max-width: 90%;
   }
  .online-portal h2 {
     font-size: 30px;
     text-align: center;
     letter-spacing:1px;
  }
  .portalBox-image {
   width:60%;
}
  .largeFont{
     font-size: 30px;
     text-align: center;
     letter-spacing:1px;
  }
.portalBox1 h4 {
   padding: 8px 2px;
   font-size: 17px;
   margin: 4px 0;
}
  .iconBox {
     max-width: 95%;
     margin: 4px auto 4px auto;
     padding: 12px 0px;
   }
   .iconOne {
     border:4px solid #fff;
     padding: 9px;
     top: -23%;
     left: 32%;
   }
   .iconSection h3 {
     margin: 10% 0 4% 0;
   }
   .iconSection p {
     font-size: 15px;
   }
   .section-bar {
     top: -20px;
     left: 1%;
     font-size: 14px;
     width: 98%;
   }
}


@media (max-width: 767.98px) { 
   .width-section {
      max-width: 95%;
    }
  .cuetlogo {
        padding: 2px 0;
    }
  .togetherLogo {
     text-align: center;
     margin: 8px 0;   
    }
  .navigation {
     display: block;
     margin: 6px 0 0 0 !important;
   }
  .navbar-toggler {
     padding: 0px 4px !important;
     font-size:20px!important;
   }
  .navbar-light .navbar-toggler {
     margin:6px 0 6px 0;
  }
  .navBarCss_ActiveButton__1nJG- {
     background: none;
     color: #3e3e3e !important;
   }
  .navbar-nav {
     text-align: center;
     border: 1px solid #aeaeae;
  }
  .navbar-nav a {
     padding: 5px 13px;
     transition: transform .3s;
     border-radius: 0px;
     letter-spacing: 4px;
  }
  .navbar-nav > li {
      background:#f5f5f59e;
      color: #292929;
      border-bottom: 1px solid #8a8181;
      padding: 4px 12px;
  }
   .navbar-nav li { 
     font-size: 16px;
     margin: 0 0px 0 0px;
  }
  .banner-section img {
     max-width: 100%;
  }
  .banner-section {
     max-width:95%;
  }
  .banner-section h1 {
     letter-spacing:2px;
     font-size:28px;
     margin: 8px 0 20px 0;
  }
  .banner-section p {
     font-size: 16px;
     text-align: justify;
     letter-spacing: 0px;
  }
  .login-section {
     padding: 12px 8px;
     margin:12px auto;
     animation: none;
  }
  .login-btn {
     margin: 6px 0 8px 0;
     width: 80%;
     padding:8px 0;
     font-size:15px;
  }
  .register-btn {
     margin: 6px 0 8px 0;
     width: 80%;
     padding:8px 0;
     font-size:15px;
  }
  .botton-banner {
     font-size:19px;
     padding: 7px 6px;
  }
  .cuet-portal {
     padding: 8px 4px 8px;
  }
  .portalBox {
      max-width: 95%;
 }
  .largeSpace {
     padding: 6px 0;
  }
  .online-portal {
     padding:12px 0;
     max-width: 90%;
   }
  .online-portal h2 {
     font-size: 30px;
     text-align: center;
     letter-spacing:1px;
  }
  .portalBox1 h4 {
      padding: 8px 2px;
      font-size: 17px;
      margin: 4px 0;
}
.portalBox-image {
     width:50%;
}
  .largeFont{
     font-size: 30px;
     text-align: center;
     letter-spacing:1px;
  }
  .iconBox {
     max-width: 95%;
     margin: 4px auto 4px auto;
     padding: 12px 0px;
   }
   .iconOne {
     border:4px solid #fff;
     padding: 9px;
     top: -23%;
     left: 32%;
   }
   .iconSection h3 {
     margin: 10% 0 4% 0;
   }
   .iconSection p {
     font-size: 15px;
   }
   .section-bar {
     top: -20px;
     left: 1%;
     font-size: 14px;
     width: 98%;
   }
   .footer-section h2 {
      padding: 10px 0;
      font-size: 20px;
   }
   .footer-section h3 {
      padding: 8px 0 8px 0;
      font-size: 18px;
      border-bottom: 2px dotted #fff;
      margin: 0 0 10px 0;
   }
   .socialHedi {
     font-size:18px;
     margin: 6px 0 6px 0;
     text-align: left;
   }
   .footer-section h4 {
      font-size: 18px;
      margin: 8px 0 8px 0;
  }
   .termsHedi {
      font-size: 18px;
      border-bottom: 1px dotted #cccc;
  }
  .list-section li {
   border-bottom: 1px dotted #cccc;
   font-size: 15px;
}
   .social-links {
     text-align: left;
   }
   .social-links img {
     margin: 3px 4px 10px 0;
   }
   .subscribe {
     text-align: left;
     padding: 10px 0 10px 0;
   }
   .address {
     font-size: 16px;
     text-align: left;
   }
   .address h4 {
     font-size: 20px;
     text-align: left;
   }
}

@media (max-width: 575.98px) { 
.width-section {
    max-width: 95%;
  }
.cuetlogo {
      padding: 2px 0;
  }
.navbar-brand{
    max-width: 80%;
}
.togetherLogo {
   text-align: center;
   margin: 8px 0;
   display: none;
  }
.navigation {
   display: block;
   margin: 6px 0 0 0 !important;
 }
.navbar-toggler {
   padding: 0px 4px !important;
   font-size:20px!important;
 }
.navbar-light .navbar-toggler {
   margin:6px 0 6px 0;
}
.navBarCss_ActiveButton__1nJG- {
   background: none;
   color: #3e3e3e !important;
 }
.navbar-nav {
   text-align: center;
   border: 1px solid #aeaeae;
}
.navbar-nav a {
   padding: 5px 13px;
   transition: transform .3s;
   border-radius: 0px;
   letter-spacing: 4px;
}
.navbar-nav > li {
    background:#f5f5f59e;
    color: #292929;
    border-bottom: 1px solid #8a8181;
    padding: 4px 12px;
}
 .navbar-nav li { 
   font-size: 16px;
   margin: 0 0px 0 0px;
}
.banner-section img {
   max-width:85%;
}
.banner-section {
   max-width:95%;
}
.banner-section h1 {
   letter-spacing:2px;
   font-size:22px;
   margin: 8px 0 20px 0;
}
.banner-section p {
   font-size: 16px;
   text-align: justify;
   letter-spacing: 0px;
}
.user {
   margin:12px 0;
}
.login-section {
   padding: 12px 8px;
   margin: 8px auto;
   animation: none;
}
.login-btn {
   margin: 6px 0 8px 0;
   width: 80%;
   padding:8px 0;
   font-size:15px;
}
.register-btn {
   margin: 6px 0 8px 0;
   width: 80%;
   padding:8px 0;
   font-size:15px;
}
.botton-banner {
   font-size:18px;
   padding: 7px 6px;
}
.cuet-portal {
   padding: 8px 4px 8px;
}
.portalBox {
   max-width:95%;
}
.portalBox1 {
   padding: 6px;
   margin: 6px 3px;
   height: 92%;
}
.largeSpace {
   padding: 6px 0;
}
.online-portal {
   padding:12px 0;
   max-width: 90%;
 }
.online-portal h2 {
   font-size: 30px;
   text-align: center;
   letter-spacing:1px;
}
.largeFont{
   font-size: 30px;
   text-align: center;
   letter-spacing:1px;
}
.iconBox {
   max-width: 95%;
   margin: 4px auto 4px auto;
   padding: 12px 0px;
 }
 .iconOne {
   border:4px solid #fff;
   padding: 9px;
   top: -23%;
   left: 32%;
 }
 .iconSection h3 {
   margin: 10% 0 4% 0;
 }
 .iconSection p {
   font-size: 15px;
 }
 .section-bar {
   top: -20px;
   left: 1%;
   font-size: 14px;
   width: 98%;
 }
 .footer-section h2 {
   padding: 12px 0;
   font-size: 18px;
 }
 .footer-section h3 {
   font-size: 19px;
   border-bottom: 2px dotted #fff;
   margin: 0 0 10px 0;
   text-align: center;
 }
 .termsHedi {
   font-size: 18px;
   margin: 4px 6px 4px 6px;
}
.list-section li {
   font-size: 14px;
}
 .footer-section p {
   letter-spacing: 0px;
   font-size: 15px;
 }
 .socialHedi {
   font-size: 18px;
   margin: 3px 0 3px 0;
}
 .social-links {
   text-align: center;
 }
 .social-links img {
   margin: 3px 4px 10px 0;
 }
 .footer-section h4 {
   font-size: 20px;
   margin: 8px 0 8px 0;
   text-align: center;
}
 .subscribe {
   text-align: center;
   padding: 10px 0 10px 0;
 }
 .address {
   font-size:15px;
   text-align: center;
 }
 .address h4 {
   font-size: 20px;
   text-align: center;
 }
}

