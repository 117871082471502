/* Left Navbar */
.leftNavigation{
position: fixed;
left:0 ;
width: 289px;
background: #174f8f;
height: 100%;
transition: 1s;
overflow: hidden;
white-space: nowrap;
z-index: 9999;
}
.headerLeft{
  background: #a37af4;
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 3fr 10fr 2fr;
}
.colm{
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  margin: 13px 0 0 6px;
}
.DashBoardIcon{
  font-size: 29px;
  color: #fff;
  margin: 0 18px 0 0;
  cursor: pointer;
}
.leftButton:hover{
  background: #2c66a9;
}
.leftButton{
  background: #276ab7;
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 2fr 10fr;
  cursor: pointer;
  border-bottom: 1px solid #4e94e5;
}
.iconLeft{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 2px;
  background: #042954;

}
.iconLeft img{
  max-width: 100%;
  background: #fff;
  border-radius: 50%;
}
.leftText{
  font-size: 17px;
  color: #fff;
  text-align: left;
  margin: 4px 0 0 15px;
}
/* Left Navbar */

/* right side */
.rightArea{
  background: #f0f1f3;
  min-height: 100vh;
  transition: 1s;
  position: relative;
}
.HeaderTop{
  padding: 0 0px 0 0;
  box-shadow: 2px 3px 5px #c5c5c5;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1050;
}
.logoimgs{
  max-width: 100%;
  padding: 14px 0 14px 12px;
}
.candidateButton{
  background: #fff !important;
  border: none !important;
  color: #000 !important;
}
.candidateButton:focus{
  outline: 0 !important;
}
.user{
  font-size: 39px !important;
  color: #042954;
}
.boxWithIcon img{
  max-width: 100%;
  margin: 10px 0 0 0;
}
.boxWithIcon{
  width: 150px;
  height: 150px;
  border: 8px solid #174f8f;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  transition: 1s;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 30%), 0 15px 0 rgb(0 0 0 / 1%)
}
.buttonBottomTxt{
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
}
.cricleBg{
  text-align: center;
}
.boxWithIcon:hover{
  background: rgba(255, 255, 255, 0.05);
    -webkit-transform: scale(0.93);
    -moz-transform: scale(0.93);
    -ms-transform: scale(0.93);
    transform: scale(0.93);
    box-shadow: 0 0 0 2px rgba(192, 192, 192, 0.1), 0 0 10px 10px #e2e2e2, 0 0 0 10px rgba(231, 222, 222, 0.5);
    -webkit-transform: scale(0.9);
    opacity: 2;
    background: #f4f9ff;
}
.OrderStatus{
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
}
.pendingstatus{
  background: #ffbc5c;
  padding: 7px 15px;
  text-align: center;
  color: #0e0e0e;
  border-radius: 34px;
  width: 150px;
}
.paymentS{
  background: #01ec3c;
  padding: 7px 15px;
  text-align: center;
  color: #0e0e0e;
  border-radius: 34px;
  width: 150px;
}
.backDivButton{
  background: #fff;
  padding: 50px 15px;
  margin: 30px 15px;
  border-bottom: 2px solid #174f8f;
}
.backDivOrder{
  background: #fff;
  padding: 30px 15px;
  margin: 30px 15px;
}
.tableOrderList{
  white-space: nowrap;
}
footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background: #174f8f;
  text-align: center;
}
.theadHeader{
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
  background: #ededed;
}
.pendingButton{
  background: #f7f7f7;
  border-radius: 31px;
  display: inline-block;
  padding: 5px 21px;
  width: 146px;
  text-align: center;
}
.Attempted{
  background: #f7f7f7;
  border-radius: 31px;
  display: inline-block;
  padding: 5px 21px;
  width: 146px;
  text-align: center;
}
.AttemptedIcon{
  color: #04a12b;
}
.ReportSubminu{
  background: rgb(48 126 217);
  padding: 15px 15px;
  color: #fff;
  font-size: 17px;
  text-align: left;
  border-bottom: 1px solid #999;
  cursor: pointer;
}
.ReportSubminu:hover{
  background: rgb(27, 113, 211);
}
.secondSection{
    padding: 30px 0;
    margin: 30px 15px;
}
.accountBg{
  display: grid;
  grid-template-columns: 5fr 10fr;
  background: #fff;
  padding: 30px 10px;
  margin: 10px 0;
}
.imgIcon{
  max-width: 100%;
  margin: 10px;
}
.imgIcon:hover{
opacity: 0.8;
}
.TextAreaRight{
  text-align: right;
}
.textHeading{
  font-size: 17px;
  color: #000;
  margin: 8px 0 0 0;
}
.button1Account, .button2Account, .button3Account{
  background: rgb(56 181 116);
  padding: 5px 15px;
  font-size: 16px;
  width: 80px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  margin: 15px 0;
}
.button2Account{
  background: rgb(63 122 252);
}
.button3Account{
  background: rgb(255 160 1);
}
.logoimgs{
  max-width: 100%;
  padding: 14px 0 14px 12px;
}
.rightcolm{
  text-align: right;
  padding: 5px 0 0 0;
}
.logoDiv{
  text-align: left;
  /* background: #174f8f; */
  width: 289px;
}
.logoCol{
  float: left;
}
.clear{
  clear: both;
}
.UserCol{
  float: right;
}
.LeftContainer{
  background: #fff;
  padding: 15px;
  margin: 15px;
  margin-left: 303px;
}
.BkgenerateExam{
  background: rgb(255 255 255);
  padding: 50px 10px;
  margin: 20px 0;
  margin-left: 296px;
}
.HeadingG img{
  width: 50px;
    background: #fff;
    border: 1px solid;
    border-radius: 50%;
    margin: 0 10px 0 0;
}
.HeadingG{
  text-align: left;
  border-bottom: 1px solid rgb(216, 213, 213);
  padding: 5px;
  font-size: 18px;
  color: #084a95;
  margin: 10px 0;
}
.boxesIcon{
  background: #fff;
  padding: 10px;
  box-shadow: -1px 3px 15px #d7d5d5;
  max-width: 300px;
  margin: 10px auto;
  border-radius: 10px;
  text-align: center;
  transform: 2s;
  cursor: pointer;
}
.boxesIcon:hover{
  box-shadow: 0px 1px 1px #d7d5d5;
  transition: 1s;
}
.boxesIcon:focus{
   background: #f9f9f9;
}
.generatePrcticeSection{
  padding: 47px 0% 0 5.5%;
  transition: 5s;
  
}
.SectionLabel{
  color: #777;
  padding: 9px 17px;
  border-radius: 4px;
  margin: 20px 20px;
  font-size: 16px;
  display: inline-flex;
  background: linear-gradient(#f5fcff, #f0f0f0);
  cursor: pointer;
  border: 1px solid #e6e6e6;
}
.SectionLabel input[type="radio"] {
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 50px;
  height: 25px;
  border: 0.15em solid currentColor;
  border-radius: 50%;
}
.slectTag{
    width: 100%;
    height: 44px;
    border:none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 4px;
    margin: 6px 0;
    background: #fff;
    padding: 0 10px;
}
.slectTag:focus{
  outline: none;
  border-bottom: 1px solid #c0bfbf;
}
.formBgA{
    margin: 2% 0 0 1.6%;
    background: #efefef;
    padding: 15px;
}
.inputHolder{
  margin: 15px 0;
}
a{
  text-decoration: none !important;
}
@media (min-width:20) and (max-width:800x) {
  .adminDashboard_leftNavigation__v_KsJ {

    width: 53px !important;

}
}
/* right side */


/*manage Student*/
.bg{
  height: calc(100vh - 66px);
  background: #f0f1f3;
}
.btnNavBar{
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #C4C5C6;
  background-color: #F0F1F3;
  margin: 0px !important ;
    /* grid-template-columns: repeat(4, 1fr); */
  /* position: fixed;
  left: 0;
  right: 0; */
}
.finder{
  align-items: center;
}
.searchBarBox{
  background: #fff;
  border-radius: 4px;
  padding: 4px;
}
.addStudent{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.searchIcon{
  /* padding: 2px; */
  color: #9ea0a2;
  margin-top: 2px;
}
.addStudent input{
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  margin-right: 2px;
}
.backbutton{
  text-align: right;
}
.backLinkDash{
  background: #0d6efd;
  color: #fff;
  display: inline-block;
  padding: 6px 15px;
  border-radius: 3px;
  cursor: pointer;
}

.addStudForm{
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  padding: 10px;
  margin: 2% 20% 0% 20%;

}
.inputBox input{
  width: 100%;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
}

.saveBtn{
  text-align: center;
  padding: 20px;
}
.studentList{
  padding: 20px;
  animation: fadeInDown 1s;
}
.addStuList{
  width: 100%;
  margin: auto;
  overflow-x: scroll;

}
.addStuList tr th{
  background: #0d6efd;
  padding: 10px;
  color: #fff;
}
.addStuList tr{
  background: #fff;
  border-bottom: 1px solid gray;
  padding: 10px;
}
.addStuList tr:hover{
  background: rgb(209, 209, 209);
}
.addStuList tr td{
  padding: 6px 10px;
}
.stuListBtn{
  font-size: 20px  !important;
}
.AcademicYear{
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  padding: 10px;
  margin: 0 20%;
}
.addStudentSec{
  animation: fadeInDown 1s;
}
.setWidth{
  width: 80px;
}


/*popup*/
.outGray{
  background:  rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10005;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 10%;
}
.mainContainer{
  min-width: 80%;
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  animation: fadeInDown 1s;
  text-align: center;
}
.mainHead h1{
  color: #2D72FF;
}
.popupSavBtn{
  margin-top: 10px;
}
.editInstText{
  width: 100%;
  margin-bottom: 10px;
}
/*popup*/





@keyframes fadeInDown {
  0%{
      opacity: 0;
      transform: translateY(-100px);
  }
  100%{
      opacity: 1;
      transform: translateY(0);
  }
}



@media screen and (max-width: 991px) {
  .addStudForm {
    grid-template-columns: 1fr;
    margin: 0;
  }
  .AcademicYear{
    margin: 0;
  }
  .inputBox input{
    min-width: 100%;
  }
  .addStudForm{
    grid-gap: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width:462px)  {
  .AcademicYear{
    grid-template-columns: 1fr 2fr;
    
  }  
}
/*manage Student*/

/* Super Admin Css start*/
.buttonBgs{
  background: #fff !important;
  padding: 15px;
  margin: 15px;
  animation: fadeInDown 1.5s;
}
.SchoolpopupForm{
  background: #174f8f26;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow: auto;
}
.formBgs{
  background: #fff;
  padding: 40px 20px;
  margin: 6% 0 0 0;
  border-radius: 10px;
  border-left: 5px solid #2D72FF;
  position: relative;
  animation: fadeInDown 1s;
}
.heading{
  font-size: 17px;
  color:#000;
}
.inputsStyle{
  margin: 8px 0 !important;
  border-left: 5px solid #afafaf !important;
}
.labelText{
  margin-top: 10px;
}
.crossIcon{
  position: absolute;
  right: 10px;
  top: 32px;
  font-size: 39px !important;
  color: #9b9b9b;
  cursor: pointer;
}
.subjectListPopupBg{
  background: #00000040;
  position: fixed;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.popupInnerDiv{
  position: absolute;
  width: 452px;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  animation: fadeInDown 0.5s;
  overflow: auto;
}
.checkboxBgs label:hover{
  background: rgb(233, 233, 233);
}
.checkboxBgs label{
  /* width: 77%; */
  width: calc(100% - 150px);
  background: #fff;
  padding: 8px 2px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  cursor: pointer;
  float: left;
  margin-left: 10px;
}
.checkboxBgs input{
  width: 29px;
  height: 24px;
  margin: 0 8px 0 0;
}
.buttoninFooter, .footerbuttonRow{
  margin: 10px 0 0 0;
  margin-top: 10px;
  position: sticky;
  bottom: 0px;
  z-index: 9;
  padding: 5px;
  background: #fff;
}
.buttoninFooter button{
  margin: 0 10px 0 0;
}
.ManageStudent{
  background: #174f8f26;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow: auto;
}
.tableBgsS{
  background: #fff;
  padding: 15px;
  margin: 0% 0 0 0;
  animation: fadeInDown 0.5s;
}
.tableBgs{
  background: #fff;
  padding: 15px;
  margin: 5% 0 0 0;
  animation: fadeInDown 1s;
}
.stulist{
  font-size: 17px;
  margin: 8px 0;
}
.inputFiled input{
  max-width: 300px;
  margin: 0px 0;
  border-radius: 0 4px 4px 0;
}
.inputFiled{
  display: flex;
  justify-content: right;
}
.inputFiled2 input{
  max-width: 100%;
  margin: 0px 0;
  border-radius: 0 4px 4px 0;
}
.inputFiled2{
  display: flex;
  justify-content: right;
}
.searchIcons{
  font-size: 38px !important;
  background: #747577;
  color: #fff;
  border-radius: 4px 0 0 4px;
  padding: 4px 0;
}



.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.TableManageT{
  white-space: nowrap;
}

.TableManageT input:checked + .slider {
  background-color: #0BB916;
}

.TableManageT input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.TableManageT input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.editButton:hover{
  background: #04a12b;
  color: #fff;
}
.editButton{
  background: rgb(240, 240, 240);
  color: rgb(32, 128, 3);
  padding: 1px;
  border-radius: 4px;
  font-size: 31px !important;
  border:1px solid rgb(32, 128, 3);
  cursor: pointer;
}
.delete:hover{
  background: #f04d1b;
  color: #fff;
}
.delete{
  background: rgb(240, 240, 240);
  color: rgb(233, 60, 48);
  padding: 1px;
  border-radius: 4px;
  font-size: 31px !important;
  border:1px solid rgb(233, 60, 48);
  cursor: pointer;
}
.AddStudentBk{
  background: rgba(8, 8, 8, 0.192);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  overflow: auto;
}
.innerBox{
  max-width: 400px;
  height: 100%;
  background: #fff;
  animation: fadeInDown 0.9s;
  overflow: auto;

}
.innerBox{
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 400px;
  height: 100%;
  margin: 10px;
  text-align: center;
}


.addstuHeading{
  background: rgb(36, 152, 255);
  padding: 9px 0 9px 20px;
  color: #fff;
  font-size: 21px;
}
.addstuForm{
  padding: 15px;
}
.addStuForm{
  background: rgb(238, 238, 238);
  padding: 10px 10px;
  margin: 20px 0;
  border-radius: 5px;
}
.addstuForm input{
  border-left:5px solid rgb(201, 201, 201);
}
.addstuForm select{
  border-left:5px solid rgb(201, 201, 201);
}
.footerbuttonRow button{
  margin: 0 10px 0 0;
}
.SchoolRegisterBk{
  background: rgba(8, 8, 8, 0.192);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}
.AllocateSubjectList{
  background: #00000040;
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}
.allo_subjact{
  max-width: 467px;
  height: 100%;
  background: #fff;
  animation: fadeInDown 0.9s;
  overflow: auto;
}
.AlocateSubjectFormBk{
  padding: 10px 6px;
  background: #fff;
  border: 1px solid #f3f3f3;
  margin: 10px;
  box-shadow: 2px 3px 4px #ededed;
}
.AllocateSubForStu{
  background: #00000040;
  position: fixed;
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}
.allo_subjact_ForStu{
  max-width: 400px;
  height: 100%;
  background: #fff;
  animation: fadeInDown 0.9s;
  overflow: auto;
}
.Heading{
  font-size: 17px;
  color: #000;
  margin: 13px 0 0 0;
}
.backTohome{
  text-align: right;
}
.backSelect{
  background: #f1f1f1;
  padding: 15px 10px;
  margin: 20px 0;
}
.learnBtn:hover .SubjContainer{
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}

.learnBtn:hover .logoCircle{
  border: 2px solid #9966FF;
}
.learnBtn:hover .logoCircle img{
  transform: rotate(10deg) scale(1.05);
}
.learnBtn:hover .headingDiv{
  background: #9966FF;
}
.learnBtn:hover .dropArrow{
  color: #9966FF !important;
}

.learnBtn{
  position: relative;
  padding: 20px;
  cursor: pointer;
  max-width: 315px;
  margin:  auto;
}
.logoCircle{
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1B60CC;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 0;
}
.logoCircle img{
  width: 100%;
  transition: transform .7s;
}
.headingDiv{
  background: #1B60CC;
  padding: 10px 4px 10px 48px;
  border: 2px solid #fff;
  border-radius: 50px;
  max-width: 275px;
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.headingwithBack{
  padding: 5px 10px;
  background: #f2f2f2;
  border-bottom: 1px solid #d9d9d9;
}
.backSection{
  animation: fadeInLeft 0.5s;
  text-align: center;
  margin: 15px 0;
}
.sectionbox:hover{
  background: #bdbdbd;
}
.sectionbox{
  margin: 0 auto;
  background: #ececec;
  padding: 7px 30px;
  font-size: 19px;
  border-radius: 20px;
  z-index: 2;
  cursor: pointer;
  max-width: 153px;
  display: inline-block;
  margin: 10px;
}
.sectionboxX{max-width: fit-content;}
.AssessmentList table{
  background: #fff;
  white-space: nowrap;
}
.AssessmentList{
  padding: 15px;
  background: rgb(247, 247, 247);
  animation: fadeInLeft 0.5s;
}
@keyframes fadeInLeft {
  0% {
     opacity: 0;
     transform: translateX(-20px);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
} 
.viewDetailesButton{
  background: #f7f7f7;
  border-radius: 31px;
  display: inline-block;
  padding: 5px 21px;
  max-width: 166px;
  text-align: center;
  cursor: pointer;
}
.mainHeading{
  font-size: 17px;
}
.viewDetailesPop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #468bf1;
  padding: 15px;
  overflow: auto;

}
.reviewAnswerBk{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #2e4a8933;
  z-index: 99999;
  overflow: auto;
}
.viewDetailesButtond{
  background: hsl(0deg 0% 98%);
  padding: 5px 5px;
  color: #161616;
  border-radius: 5px;
  text-align: center;
  border-radius: 53px;
  cursor: pointer;
  width: 136px;
  cursor: not-allowed;
}
.reviewAnswerBk{
  background: #056fe934;
  padding: 15px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  animation: fadeInDown 1s;
  overflow: auto;
}
.innerdivReport{
  background: #fff;
  padding: 15px;
  animation: fadeInLeft 0.5s;
  border-radius: 2px;
  position: relative;
}
.closeViewDetailes{
  font-size: 40px !important;
  position: absolute;
  right: 24px;
  top: 10px;
  color: #9b9a9a;
  cursor: pointer;
}
.headingCol{
  font-size: 18px;
  padding: 10px 0;
}
.viewDetailesPop table{
  white-space: nowrap;
}
.checkMark{
  color: #0ac037;
  font-size: 30px !important;
}
.wrongSign{
  color: #f72d12;
  font-size: 30px !important;
}
.ReviewAnswer_section{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #468bf1;
  padding: 15px;
  overflow: auto;
}
.rowGrid{
  display: grid;
  grid-template-columns: 4fr 96fr;
  margin: 4% 10%;
  min-height: 500px;
  font-size: 19px;
}
.QuestionNo {
  color: #96f;
  font-weight: 700;
}
.optionsRow{
  display: grid;
  grid-template-columns: 5fr 95fr;
}
.optionsRow input[type=radio] {
  background-color: #fff;
  font: inherit;
  color: currentColor;
  height: 15px;
  width: 21px;
  border: 0.15em solid #a37af4;
  border-radius: 50%;
  cursor: pointer;
}
.colFixed {
  width: 106px;
}
.divOne{
  white-space: nowrap;
  font-size: 18px;
  font-weight: 700;
  width: 57px;
}
.headingExpl {
  color: #96f;
  font-weight: 700;
  font-size: 19px;
}
.explanationBox {
  margin: 1% 10%;
}
.expContent {
  font-size: 17px;
  color: #000;
}
.questionDiv label {
  margin: 20px 0;
}
.selectSchoolbk{
  padding: 15px 10px;
  background: #f0f0f0;
  margin: 20px 0;
}
.inputWithSubject{
  border-radius: 3px;
  float: right;
  width: 130px !important;
  height: 35px !important;
  margin: 2px 0 0 0 !important;
  border: 2px solid #9abef7;
}
.clear{
  clear: both;
}
.rowSec{
  display: flex;
  margin: 15px 0 0 0;
  margin: 8px 0 !important;
  border-left: 5px solid #afafaf !important;
  border: 1px solid #d3d3d3;
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  padding: 5px 0;
}
.sectionDiv{
  background: #f4f4f4;
  padding: 2px 5%;
  font-size: 16px;
  border-radius: 0px;
  z-index: 2;
  cursor: pointer;
  margin: 3px 10px 5px 0;
}
.SubmitBTN{
  border-left: 0 !important;
}
.showErrbase{
    background: #00000040;
    position: fixed;
    z-index: 9999999999999999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inerBox{
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    width: 400px;
    margin: 10px;
    text-align: center;
    animation: fadeInDown 1s;

  }
  .errIcons{
    font-size: 60px !important;
    color: #ffbc5c;
  }
  .errMessageDiv{
    border-radius: 4px;
    box-shadow: none;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: rgb(253, 237, 237);
    padding: 10px 16px;
    color: rgb(95, 33, 32);
    margin: 23px 0;
  }
  .successIcons{
    font-size: 100px !important;
    color: #90db80;
  }
  .successMes{
    border-radius: 4px;
    box-shadow: none;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: rgb(226, 249, 232);
    padding: 10px 16px;
    color: rgb(42, 140, 39);
    margin: 23px 0;
  }
  .showErrbase{
    background: #00000040;
    position: fixed;
    z-index: 9999999999999999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inerBox{
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    width: 400px;
    margin: 10px;
    text-align: center;
    animation: fadeInDown 1s;

  }
  .errIcons{
    font-size: 60px !important;
    color: #ffbc5c;
  }
  .errMessageDiv{
    border-radius: 4px;
    box-shadow: none;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: rgb(253, 237, 237);
    padding: 10px 16px;
    color: rgb(95, 33, 32);
    margin: 23px 0;
  }
  .successIcons{
    font-size: 100px !important;
    color: #90db80;
  }
  .successMes{
    border-radius: 4px;
    box-shadow: none;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: rgb(226, 249, 232);
    padding: 10px 16px;
    color: rgb(42, 140, 39);
    margin: 23px 0;

  }
  .tableListOf{
    margin: 15px 0;
    background: rgb(247, 247, 247);
    padding: 10px;
    animation: fadeInDown 1s;

  }
  .tableListOf table{
    background: rgb(255, 255, 255);
  }
  .tableListOf table thead{
    background: rgb(209, 245, 248);
  }
  .comboBoxBgs{
    background: #e8e8e8;
    padding: 17px 10px;
  }
.basePopDiv{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000096;
  z-index: 9999;
  overflow: auto;
}
.checkBoxInputs{
  display: flex;
  align-items: center;
  cursor: pointer;
  /* margin: 0 auto; */
  margin-left: -25px;
  width: calc(100% - 145px);
}
.checkBoxInputs input{
  width: 25px;
  height: 25px;
  margin: 0 5px 0 0;
  cursor: pointer;
}
.checkBoxInputs1{
  display: flex;
  align-items: center;
  cursor: pointer;
  /* margin: 0 auto; */
  margin-left: -25px;
  /* width: calc(100% - 145px); */
}
.checkBoxInputs1 input{
  width: 25px;
  height: 25px;
  margin: 0 5px 0 0;
  cursor: pointer;
}
.fixedBottomButton{
  position: sticky;
  bottom: 0px;
  background: #fff;
  padding: 5px 0;
}
.ManageStudentBg{
  background: rgb(241, 241, 241);
  padding: 15px 0;
}
.subjectsecBox{
  margin: 15px 0;
  background: #fff;
  padding: 5px;
  border: 1px solid #e8e8e8;
  box-shadow: 2px 3px 18px #0b0b0b29;
  animation: fadeInDown 1s;
}
.basePopDiv{
  position: fixed;
  background: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
}
.innerBoxPop{
  width: 400px;
  height: 100%;
  background: #fff;
  animation: fadeInDown 0.5s;
}
.headingDivAssign{
  background: #2076f7;
  padding: 10px;
  font-size: 20px;
  color: #fff;
}
.labelRow{
  width: 100%;
  background: rgb(253, 253, 253);
  padding: 5px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.labelRow input{
  width: 25px;
  height: 25px;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.secBox{
  margin: 30px 8px;
  background: #ebebeb;
  padding: 10px;
}
.btnRow{
  margin: 20px 8px;
  position: sticky;
  bottom: 5px;
}
.popDeleteBase{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1351ad56;
  z-index: 999999;
  overflow: auto;
}
.innerBoxes{
  padding: 15px;
  background: #fff;
  width: 500px;
  border-radius: 10px;
  margin: 10px;
  text-align: center;
}
.questionICon{
  font-size: 80px !important;
  margin: 15px 0;
  color: #f04d1b;
}
.areYousure{
  font-size: 25px;
  color: rgb(58, 56, 56);
}
.doyoureally{
  font-size: 17px;
  margin: 10px 0;
}
.buttonconfir{
  margin: 10px 20px;
  padding: 10px 35px;
}
.buttonLogout{
  font-size: 34px !important;
  background: rgb(2, 170, 67);
  color: #fff;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}
.LogoutNull{
  font-size: 34px !important;
  background: rgb(247, 65, 41);
  color: #fff;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}
.verifiedBtn{
  font-size: 34px !important;
  background: rgb(104, 104, 104);
  color: #fff;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}


.partSectionRow{
  text-align: center;
  animation: fadeInLeft 0.5s;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonsPart{
  color: #fff;
  text-transform: uppercase;
  padding: 9px 10px;
  width: 176px;
  text-align: center;
  border-radius: 0 2px 15px 15px;
  margin: 10px;
  background: #9465f2;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  border-left: 3px solid #3e103b;
  border-bottom: 3px solid #3e103b;
}
.headingDiv2{
  background: #1B60CC;
  padding: 10px 4px 10px 48px;
  border: 2px solid #fff;
  border-radius: 50px;
  max-width: 275px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  margin-top: -11px;
}
.headerTr{
  background:#cbe8ff;
  text-align:center;
}
.popupBack{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #137af036;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errBox{
  background: #fff;
  width: 500px;
  padding: 3px;
  margin: 10px;
  border-radius: 4px;
  animation: fadeInDown 1s;
}
.crossPopup{
  text-align: right;
  float: right;
  margin: 0 0 0 2px;
  background: #f3f3f3;
  height: 48px !important;
  width: 44px !important;
  border-radius: 4px;
  cursor: pointer;
}
.bulkUploadPop{
  background: #00000040;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconsUpload{
  font-size: 70px !important;
  color: rgb(179, 178, 178);
  margin: 10px 0;
}
.uolpadFilesBulk{
  text-align: left;
}
.buttonSection{
  text-align: center;
  margin: 20px 10px;
}
.buttonSection button{
  margin: 2px 10px;
}
.schoolBar{
  position: fixed;
  left: 28px;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.86);
  padding-top: 10px;
  padding: 4px;
}
.checkBoxOuter{
  display: flex;
}
.numberOfLogin{
  width: 75px;
  border-radius: 5px;
  margin-left: 5px;
}
.mar-rit-10{margin-right:10px;}

.fixedHead{
position: sticky;
top: 0;
z-index: 999;
background: #fff;
}

/* Super Admin Css end */

