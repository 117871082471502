body{
  /* font-family: 'Calibri' !important; */
  font-family: 'Poppins' !important;
    margin:0;
    padding: 0;
}
@font-face {
   font-family: 'Calibri';
   src: url('./fonts/Calibri.woff2') format('woff2'),
       url('./fonts/Calibri.woff') format('woff');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Medium.woff2') format('woff2'),
      url('./fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Marck Script';
  src: url('./fonts/MarckScript-Regular.woff2') format('woff2'),
      url('./fonts/MarckScript-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



