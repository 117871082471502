/* figure.table[style]{
  float: none !important;
  width: auto !important;
  height: auto !important;
} */

/* status popup css start */
.showErrbase{
  background: #00000040;
  position: fixed;
  z-index: 9999999999999999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inerBox{
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  width: 400px;
  margin: 10px;
  text-align: center;
  animation: fadeInDown 1s;

}
.errIcons{
  font-size: 60px !important;
  color: #ffbc5c;
}
.errMessageDiv{
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(253, 237, 237);
  padding: 10px 16px;
  color: rgb(95, 33, 32);
  margin: 23px 0;
}
.successIcons{
  font-size: 100px !important;
  color: #90db80;
}
.successMes{
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(226, 249, 232);
  padding: 10px 16px;
  color: rgb(42, 140, 39);
  margin: 23px 0;
}
.showErrbase{
  background: #00000040;
  position: fixed;
  z-index: 9999999999999999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inerBox{
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  width: 400px;
  margin: 10px;
  text-align: center;
  animation: fadeInDown 1s;

}
.errIcons{
  font-size: 60px !important;
  color: #ffbc5c;
}
.errMessageDiv{
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(253, 237, 237);
  padding: 10px 16px;
  color: rgb(95, 33, 32);
  margin: 23px 0;
}
.successIcons{
  font-size: 100px !important;
  color: #90db80;
}
.successMes{
  border-radius: 4px;
  box-shadow: none;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(226, 249, 232);
  padding: 10px 16px;
  color: rgb(42, 140, 39);
  margin: 23px 0;

}
/* status popup css end */
a {
  color: #000 !important;
  text-decoration: none;
}

figure.table table{
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0,0,0,0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0,0,0,0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0,0,0,0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
figure.table table > tbody {
  vertical-align: inherit;
}
figure.table table > :not(caption) > * {
  border-width: 1px 0;
}
figure.table table > :not(caption) > * > * {
  border-width: 0 1px;
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
figure.table table > :not(caption) > * > * {
  padding: 11px 0.5rem;
}

/* ck5 */
.ck.ck-editor {
  max-width: 99%;
}
.pagenotFound{
  text-align: center;
  margin: 5% auto;
}
.btnBottomF{
  position: sticky;
  bottom: 2px;
  margin:10px !important;
}
.pagenotFound img{
  max-width: 100%;
  text-align: center;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: none !important;
}
.ck.ck-editor__main > .ck-editor__editable{
  border-color: var(--ck-color-base-border);
  height: 200px;
}

.ck-math-widget.ck-widget:after,
.ck-math-widget.ck-widget:before{content: "  " !important;margin: 0px 5px !important;}


.ck-balloon-panel_visible{z-index: 99999 !important}


img{
  -webkit-user-drag: none !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.addMore{
  margin-right: 20px !important;
}
.addMore2{
  margin-right: 20px !important;
  width: 115px !important;
}
.editBtn{
  width: 100px;
  margin-right: 50px;
}
.DelBtn{
  width: 100px;
  margin-right: 50px;
}
.eddbtn{
  width: 100px;
  margin: 2px;
}
.table>:not(:first-child) {
   border-top: none; 
}
.pagination {
  justify-content: end;
}
.accordion-button {
  position: relative;
  display: block;
  align-items: center;
  width: 100%;
  padding: 0;
  color: #212529;
  text-align: left;
  border: 0;
  border-radius: 0;
}
 .accordion-item {
  background: none;
  border: none;
}
.accordion-body{
  background: #fff;
  padding: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #9966ff;
  border-color: #9966ff;
}
.table>:not(caption)>*>* {
  padding: 11px 0.5rem;
}
.LoderBack{
  background: rgb(0 0 0 / 38%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader{
  width: 160px;
  height: 160px;
  margin: 0 auto;
  position: absolute;
  z-index: 999999;
  background: #fff;
  border-radius: 50%;
}
.loaderBg{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000080;
  position: fixed;
  z-index: 999999999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loader .box{
  background-color: rgba(0,0,0,0.04);
  border: 4px solid transparent;
  border-left: 4px solid #26ff00;
  border-top: 4px solid #26ff00;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0,0,0,0.03);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: animate1 3s linear infinite;
}
.loader .box:nth-of-type(2){
  background-color: rgba(0, 0, 0, 0.025);
  border: 4px solid transparent;
  border-right: 4px solid #03a9f4;
  border-bottom: 4px solid #03a9f4;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  animation: animate1 3s reverse linear infinite;
}
.loader .box .circle{
  transform-origin: left;
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 2px;
}
.loader .box .circle,
.loader .box:nth-of-type(2) .circle{
  transform: rotate(-45deg);
}
.loader .box .circle:before{
  content: '';
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -6px;
}

.schoolRegister .dropdown{
  border-left: 5px solid #afafaf !important;
  background: #fff !important;
  border-radius: 5px;
  margin: 8px 0 0 0;
}
.loader .box .circle:before{
  background: #26ff00;
  box-shadow: 0 0 20px #26ff00, 0 0 40px #26ff00, 0 0 60px #26ff00, 0 0 80px #26ff00,
              0 0 100px #26ff00, 0 0 0 5px rgba(255, 255, 0, .1);
}
.loader .box:nth-of-type(2) .circle:before{
  background: #2db9ff;
  box-shadow: 0 0 20px #2db9ff, 0 0 40px #2db9ff, 0 0 60px #2db9ff, 0 0 80px #2db9ff,
              0 0 100px #2db9ff, 0 0 0 5px rgba(3, 169, 244, .1);
}

.QuickNotesButton{
  text-align: center;
  margin: 15px 0;
  position: relative;
}
.tables{
  height: 71vh;
  overflow: auto;
}

/* success popup css */
.showErrbase{
  position: fixed;
  z-index: 99999999999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.274);
  width: 100%;
  height: 100%;
}
@keyframes animate1{
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
}
/* media query  start here*/
@media (min-width:20px) and (max-width:1468px) {
  .stuDashboard_boxWithIcon__M-4VT {
    width: 111px !important;
    height: 111px !important;
    border: 4px solid #174f8f !important;
    border-radius: 50%;
}
.stuDashboard_iconPrac__1BkDU img {
  width: 51px;
}
.stuDashboard_PracSetHeading__1m2g0 {
  font-size: 15px;
  margin: 14px 0 0 0;
}

.stuDashboard_buttonBottomTxt__1fpUT {
  font-size: 13px;
  margin: 10px 0;
}
.adminDashboard_boxWithIcon__3IgzM {
  width: 139px;
  height: 139px;
  border: 8px solid #174f8f;
  
}
.adminDashboard_buttonBottomTxt__1ApX7 {
  font-size: 13px;
  color: #084a95;
  margin: 10px 0;
}
.adminDashboard_textHeading__3pqNU {
  font-size: 11px;
  color: #000;
  margin: 8px 0 0 0;
}
.stuDashboard_boxWithIcon__M-4VT img {
  max-width: 94%;
  margin: 3px 0 0 0;
}
.stuDashboard_Setsback__v2Eml {
  position: relative;
  height: 135px;
  width: 148px;
  margin: 0 auto;
}
.stuDashboard_PracSetHeading__1m2g0 {
  width: 100%;
  font-size: 17px !important;
  display: inline-block;
  padding: 33px 0 0 0;
}

}
@media (min-width:20px) and (max-width:575px) {
.stuDashboard_RowLabel__vE0nc {
  padding: 6px 7px;
}
.stuDashboard_RowCOlm__1Sizi {
  display: block;
  grid-template-columns: 10fr 10fr;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.stuDashboard_rightcolm__5JN76 {
  text-align: center;
}
.adminDashboard_leftNavigation__v_KsJ {
  position: fixed;
  left: 0;
  width: 55px;
}
.adminDashboard_BkgenerateExam__Z9E7F {
  margin-left: 57px;
}
.stuDashboard_logoDiv__3Str9 {
  text-align: center;
}
.stuDashboard_selectCombobox__w4OCu {
  width: 208px;
}
.stuDashboard_forLessPadding__2G9Wp {
  padding: 0 !important;
}
.stuDashboard_logoimgs__2aDod {
  max-width: 100%;
  padding: 7px 0 9px 0px;
}
body{
  overflow-x: hidden;
}
.stuDashboard_logoDiv__3Str9 {
  text-align: center;
  background: #174f8f;
}
.stuDashboard_UserCol__vSLL1 {
  float: right;
  text-align: center;
  width: 100%;
}
.stuDashboard_logoCol__2xWwk {
  float: none;
}
.stuDashboard_logoDiv__3Str9 {
  width: 100%;
}
.stuDashboard_buttonBottomTxt__1fpUT {
  font-size: 17px;
  color: #084a95;
  margin: 10px 0;
}
.adminDashboard_logoCol__1GGzP {
  float: none;
}
.adminDashboard_logoDiv__36PGe {
  text-align: center;
  background: #174f8f;
  width: 100%;
}
.adminDashboard_rightcolm__3-yhn {
  text-align: center;
  padding: 5px 0 0 0;
}
.adminDashboard_UserCol__--_S0 {
  float: none;
}
.adminDashboard_logoimgs__336ph {
  max-width: 100%;
  padding: 4px 0 6px 12px;
}
.stuDashboard_leftNavigation__242GC {
  width: 48px;
  top: 110px;
}
.stuDashboard_backdiv__Y0RkM {
  background: #fff;
  margin: 15px;
  margin-left: 15px;
  padding: 15px;
  margin-left: 61px;
}
.stuDashboard_rowGrid__Z4oDZ {
  display: flow-root;
  grid-template-columns: 4fr 90fr 6fr;
}
.stuDashboard_navBarNoQues__33Xy6 {
  background: #d3bcff;
  width: 100%;
  height: 50px;
  border-left: 3px solid #9966ff;
  overflow: auto;
  white-space: nowrap;
}
.stuDashboard_queNo__C94dw {
  background: #fff;
  color: #000;
  border-radius: 4px;
  width: 45px;
  text-align: center;
  margin: 15px 4px 0 0;
  font-weight: bold;
  padding: 4px 0;
  display: inline-block;
}
.Register_containerSe__ehpON {
  font-size: 13px !important;
}
.Register_regformBox__35C6n {
  z-index: 2;
  margin: 2% 2% !important;
}
.stuDashboard_ButtonNext_pre__3rnjY {
  padding: 9px 18px;
  font-size: 17px;
  border-radius: 4px;

}
.stuDashboard_ButtonNext__uGh0p {
  padding: 9px 18px;
  font-size: 17px;
  border-radius: 4px;
}
.stuDashboard_ButtonRepost__2953l {
  color: #222;
  background-color: #e7e7e7;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px 0;
  border: none;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.9s;
  text-align: center;
  width: 212px;
  margin: 20px auto;
  border-radius: 50px;
  background: linear-gradient( 358deg, #4db7f5, #5da4f5);
  color: #fff;
  cursor: pointer;
}
.stuDashboard_headingDiv__1ggyI {
  width: 188px !important;
  font-size: 11px !important;
  padding: 10px 9px 9px 31px;
}
.stuDashboard_logoCircle__3t18S {
  width: 53px;
  height: 53px;
  top: 14px;
}
.stuDashboard_buttonSection__1ctE9 {
  margin: 0 15px;
  background: #ececec;
  padding: 7px 15px;
  font-size: 17px;
  border-radius: 20px;
  z-index: 2;
  cursor: pointer;
  white-space: nowrap;
}
.stuDashboard_SectionBk__3sWcO::before {
  width: 24%;
}
}
@media (min-width:576px) and (max-width:640px) {
.stuDashboard_headingDiv__1ggyI {
  background: #1B60CC;
  padding: 10px 20px 10px 52px;
  border: 2px solid #fff;
  border-radius: 50px;
  width: 209px !important;
  color: #fff;
  text-align: center;
  font-size: 11px;
}
.stuDashboard_logoCircle__3t18S {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1B60CC;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 0;
}
}
@media (min-width:768px) and (max-width:940px) {
  .stuDashboard_headingDiv__1ggyI {
    background: #1B60CC;
    padding: 10px 1px 10px 48px;
    border: 2px solid #fff;
    border-radius: 50px;
    width: 191px !important;
    color: #fff;
    text-align: center;
    font-size: 11px;
  }
  .stuDashboard_logoCircle__3t18S {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #1B60CC;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 0;
  }
  }

@media (min-width:768) and (max-width:1328px) {
  .stuDashboard_logoimgs__2aDod {
    width: 267px !important;
    padding: 14px 0 14px 12px;
    background: #174f8f;
}
.stuDashboard_leftNavigation__242GC {
  width: 48px !important;
  top: 66px;
}
.stuDashboard_TopicWisePractice__-oN68 label {
  font-size: 13px;
  color: #042954;
}
}
@media print{
  #printablediv{
    background: #fff;
  }

}



/* media query  end here*/
