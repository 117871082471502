@media (max-width: 1199.98px) {
.about-section {
    max-width:92%;
    padding: 6px 0;
}
.about-section p {
    font-size: 16px;
    margin: 4px 0;
}  
 }

@media (max-width: 991.98px) { 
.about-section {
    max-width:95%;
    padding: 6px 0;
}
.about-section p {
    font-size: 16px;
    margin: 4px 0;
}
}
@media (max-width: 767.98px) { 
.about-section {
    max-width:95%;
    padding: 6px 0;
}
.about-section p {
    font-size: 16px;
    margin: 4px 0;
}
.fontNew {
    font-size: 28px;
    margin:10px 0;
    letter-spacing: 1px;
}
.rightImg{
    text-align: center;
}
.privacy-section{
    background-size: cover;
}
 }

@media (max-width: 575.98px) {
.about-section {
    max-width:95%;
    padding: 6px 0;
}
.about-section p {
    font-size: 16px;
    margin: 4px 0;
}
.fontNew {
    font-size: 28px;
    margin:10px 0;
    letter-spacing: 1px;
}
.rightImg{
    text-align: center;
}
.privacy-section{
    background-size: cover;
}
.Product-section {
    margin: 5px 2px;
}
.Product-section h2{
  font-size: 19px;
  padding:6px 6px;
}
.productSub{
  padding: 3px 6px 12px 6px;
}
.productSub h4{
  font-size: 18px;
}
.productSub p{
    font-size: 16px;
  }
 }
