.container{
    background-image: url(../../assets/loginBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 93vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}
.mainDiv{
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    grid-template-rows: 2;
    width: 1250px;
    color: white;
}
.followStep{
    display: flex;
    justify-content: center;
    align-items: center;
}
.TextBox{
    padding: 20px;
    text-align: justify;
    background: #0B5F9ED4;
    /* opacity: 50%; */
}
.TextBox li{
    margin-top: 4px;
}
.happyLearning{
    text-align: center;
}
.btnDiv{
    /* border: 1px solid red; */
    grid-template-columns: 1fr;
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    justify-content: center;
    padding: 0;
}

.logButton:hover{
    background: #6E1EC9;
}
.logButton{
    font-family: 'calibri';
    font-weight: bold;
    background: #8D46DC;
    color: #fff;
    position: relative;
    width: 150px;
    padding: 10px;
    border-radius: 29px;
    font-size: 19px;
    display: block;
    cursor: pointer;
    z-index: 9999;

}
.logButton:after {
    content: "";
    width: 116px;
    height: 5px;
    background: #0cf;
    background: linear-gradient(0deg,rgba(0,204,255,0),hsla(0,0%,100%,.594));
    position: absolute;
    top: 2px;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 20px;
}

/* animation css */
.context {
    top:50vh;
  }
    .context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
  }
  .area{
    background: #4e54c8;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    height:100%;
  }
  .circles{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }
  .circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }
  .circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  .circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  .circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  .circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  .circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  .circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  .circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  .circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  .circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  .circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
  
  
  @keyframes animate {
  
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
  
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
  
  }
/* animation css */

/* media query start*/

@media screen and (max-width: 1025px) {
    .container{
        padding: 0 15px;
    }
    .mainDiv{
        grid-template-columns: 1.5fr 2fr;
        width: 1250px;
        color: white;
    }
    .TextBox{
        padding: 10px;
    }
    .TextBox li{
        margin-top: 0px;
    }
    .followStep img{
        width: 95%;
    }
    
    
  }
@media screen and (max-width: 901px) {
    .container{
        padding: 0 50px;
    }
    .mainDiv{
        grid-template-columns: 1fr;
    }
    .followStep{
        display: none;
    }
    
  }
  @media screen and (max-width: 768px) {
    .container{
        padding: 0 30px;
    }
    .mainDiv{
        grid-template-columns: 1fr;
    }
    .followStep{
        display: none;
    }
    
  }
  @media screen and (max-width: 481px) {
    .container{
        padding: 0 20px;
    }
    .TextBox h1{
        text-align: center;
    }
    
  }
  /* media query end*/