.tablesBGS{
    padding: 10px;
    background: #f9f9f9;
    border-radius: 4px;
}
.tableQuestions{
    margin: 15px 0;
}
.containerChect {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .containerChect input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #2196f3;
    border-radius: 3px;
  }
  
  /* On mouse-over, add a grey background color */
  .containerChect:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .containerChect input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  .checkmarkX{
    border: 1px solid #ff0000;
  }
  .containerChect input:checked ~ .checkmarkX {
    background-color: #ff0000;
  }
  .containerChect input:checked ~ .QuestionsSpanX {
    text-decoration: line-through;
  }
  .containerChect .checkmarkX:after {
    border-width: 0 0px 0px 0 !important;
    transform: rotate(0deg) !important;
    content: "X";
    color: #fff;
    line-height: 1.2;
    text-align: center;
    margin-left: -1px;
    font-weight: bold;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .containerChect input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .containerChect .checkmark:after {
    left: 9px;
    top: 3px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .headers_thead{
    background: rgb(224 244 255);
  }
  .PracticesSetHeading{
      text-align: right;
      margin: 34px 0 0 0;
      font-size: 18px;
      color: rgb(0, 0, 0);
  }
.PaesNabbar{
    display: grid;
    grid-template-columns: 10fr 10fr;
}
.bodyPart{
    text-align: center;
    margin: 10px 0;
}
.messageBox{
    border-radius: 4px;
    box-shadow: none;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: rgb(245 245 245);
    display: flex;
    padding: 6px 16px;
    color: rgb(0 0 0);
    font-size: 18px;
}
.QuestionsSpan{
  margin: 0px 0 0 30px;
  display: inline-block;
}
.seCondCheckmark{
  top: 10px;
}
.fixedBottom{
  position: sticky;
  bottom: 0;
}
